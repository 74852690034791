const HIGH_PRIORITY = 2;
const MEDIUM_PRIORITY = 1;
const LOW_PRIORITY = 0;

const PROBABILITY = {
  2: 0.5,
  1: 0.8,
  0: 0.99,
};

const shuffle = (input) => {
  if (!input) return [];
  const array = input.slice();
  let currentIndex = array.length;
  while (currentIndex !== 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};

const getQuestionByPriority = (input) => {
  const questions = shuffle(Array.from(input));
  const priority = getPriority();
  const questionsByPriority = getQuestionsByPriority(questions, priority);
  const nextQuestion = questionsByPriority.length > 0 ? questionsByPriority[0] : questions[0];
  const questionWithShuffledAnswers = {
    ...nextQuestion,
    options: shuffle(Array.from(nextQuestion.options)),
  };
  return {
    question: questionWithShuffledAnswers,
  };
};

const getPriority = () => {
  const random = Math.random();
  if (random <= PROBABILITY[HIGH_PRIORITY]) return HIGH_PRIORITY;
  if (random <= PROBABILITY[MEDIUM_PRIORITY]) return MEDIUM_PRIORITY;
  return LOW_PRIORITY;
};

const getQuestionsByPriority = (questions, priority) => {
  return questions.filter((q) => q.priority === priority);
};

export { getQuestionByPriority };
