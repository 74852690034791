const TimerCountdownWorker = () => {
  let countdownInterval;
  let initCountdown;
  let timeEndsAt;
  let autoRestart;

  onmessage = function (event) {
    if (event.data.clear) {
      clearInterval(countdownInterval);
      return;
    }
    initialize(event.data);
  };

  const initialize = (timer) => {
    initCountdown = timer.durationInSeconds;
    timeEndsAt = new Date(timer.startedAt);
    timeEndsAt.setSeconds(timeEndsAt.getSeconds() + initCountdown);
    autoRestart = timer.autoRestart;

    countdownInterval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    calculateRemainingTime();
  };

  const calculateRemainingTime = () => {
    if (!timeEndsAt) return;

    let diff = calculateDifference(timeEndsAt);

    let message;

    if (diff > 0) {
      message = {
        remainingTime: formatRemainingTime(diff),
        ended: false,
      };
    } else if (!autoRestart) {
      message = {
        remainingTime: '00:00:00',
        ended: true,
      };
    } else {
      timeEndsAt.setSeconds(timeEndsAt.getSeconds() + initCountdown);
      diff = calculateDifference(timeEndsAt);

      message = {
        remainingTime: diff > 0 ? formatRemainingTime(diff) : '00:00:00',
        ended: false,
      };
    }

    postMessage(message);
  };

  const calculateDifference = (timeEndsAt) => {
    const now = new Date();
    const nowFixed = now.getTime() - now.getTimezoneOffset() * 60000;
    return timeEndsAt.getTime() - timeEndsAt.getTimezoneOffset() * 60000 - nowFixed;
  };

  const formatRemainingTime = (diff) => {
    let hours = Math.floor(diff / 3.6e6);
    let minutes = Math.floor((diff % 3.6e6) / 6e4);
    let seconds = Math.floor((diff % 6e4) / 1000);
    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
};

export default TimerCountdownWorker;
