import Loading from '../components/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Alert,
  Label,
  Col,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Row,
} from 'reactstrap';
import { validateImageFile } from '../utils/componentUtil';
import ReturnButton from '../components/ReturnButton';
import { LEVEL_OPTIONS, BACKEND_URL, PIECE_OPTIONS } from '../utils/constants';
import { applyHeaders } from '../api/axiosClient.js';
import { useActor } from '../storage/storage.js';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const NewPrize = () => {
  const { error, getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const [prize, setPrize] = useState({});
  const [state, setState] = useState({
    redirect: false,
    error: null,
  });
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleImage = async (event) => {
    const file = event.target.files[0];
    const error = validateImageFile(file);
    if (error) {
      setImageFile(null);
      return setState({ ...state, error });
    }
    setImageFile(file);
    setState({ ...state, error: null });
  };

  const handlePrizeInput = (event) => {
    setPrize({
      ...prize,
      [event.target.name]: event.target.value,
    });
  };

  const submit = async (event) => {
    event.preventDefault();

    if (!imageFile) return;
    setLoading(true);

    const newPrize = new FormData();
    newPrize.append('name', prize.name);
    newPrize.append('companyId', actor.company_id);
    newPrize.append('total_pieces', prize.total_pieces);
    newPrize.append('level', prize.level);
    newPrize.append('image', imageFile);
    if (prize.description) {
      newPrize.append('description', prize.description);
    }

    const headers = await applyHeaders(getAccessTokenSilently, 'multipart/form-data');
    await axios
      .post(`${BACKEND_URL}/prize`, newPrize, headers)
      .then((_) =>
        setState({
          ...state,
          redirect: true,
          error: null,
        })
      )
      .catch((error) =>
        setState({
          ...state,
          error: error.response.data.message,
        })
      )
      .finally(() => setLoading(false));
  };

  if (error) return <p>{error.message}</p>;

  if (state.redirect) return <Navigate to="/prize" />;

  return (
    <>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('catalogue.add')}</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={submit}>
            <Row>
              <Col md={5}>
                <Card
                  id="prizeCard"
                  style={{
                    width: '26rem',
                  }}
                >
                  <CardHeader>
                    <Label className="admin-text" for="file">
                      {t('form.img')}
                    </Label>
                    <Input id="file" name="file" type="file" accept="image/*" onChange={handleImage} />
                  </CardHeader>
                  <CardBody>
                    {imageFile && (
                      <CardImg
                        id="image"
                        src={URL.createObjectURL(imageFile)}
                        alt="Imagen premio"
                        style={{
                          width: '24rem',
                        }}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <FormGroup floating>
                  <Input id="name" name="name" placeholder={t('form.name')} type="text" onChange={handlePrizeInput} />
                  <Label className="admin-text" for="name">
                    {t('form.name')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="level"
                    name="level"
                    placeholder={t('catalogue.level')}
                    type="select"
                    defaultValue={-1}
                    onChange={handlePrizeInput}
                  >
                    <option />
                    {LEVEL_OPTIONS.map((o) => (
                      <option key={o.value} value={o.value}>
                        {t(o.label)}
                      </option>
                    ))}
                  </Input>
                  <Label className="admin-text" for="level">
                    {t('catalogue.level')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="pieces"
                    name="total_pieces"
                    placeholder={t('catalogue.pieces')}
                    type="select"
                    onChange={handlePrizeInput}
                  >
                    <option />
                    {PIECE_OPTIONS.map((o) => (
                      <option key={o.value} value={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </Input>
                  <Label className="admin-text" for="pieces">
                    {t('catalogue.pieces')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="description"
                    name="description"
                    type="textarea"
                    onChange={handlePrizeInput}
                    style={{
                      height: '8rem',
                      resize: 'none',
                    }}
                  />
                  <Label className="admin-text" for="description">
                    {t('form.desc')}
                  </Label>
                </FormGroup>
                <FormGroup className="my-2">
                  <ReturnButton to={'/prize'} />
                  <Button color="primary" type="submit" disabled={loading}>
                    {t('buttons.ok')}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default withAuthenticationRequired(NewPrize, {
  onRedirecting: () => <Loading />,
});
