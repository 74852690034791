import React from 'react';

import Header from './Header';
import Footer from './Footer';

import '../assets/css/palette.css';
import '../assets/css/typography.css';
import '../assets/css/client.css';

const GameContainer = ({ children, active }) => {
  return (
    <div className="client-app">
      <Header />
      <main>{children}</main>
      <Footer active={active} />
    </div>
  );
};

export default GameContainer;
