import React from 'react';
import { useJackpot } from '../../storage/storage';
import JackpotCard from './JackpotCard';

import '../../i18n/i18n';

const JackpotCatalog = () => {
  const jackpot = useJackpot();

  return (
    <div className="sub-container">
      <JackpotCard jackpot={jackpot} />
    </div>
  );
};

export default JackpotCatalog;
