import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import LoadingSpinner from '../client/questionary/components/LoadingSpinner';
import { useDispatch } from 'react-redux';
import { logout as appLogout } from '../state/auth';

const Logout = () => {
  const { logout } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appLogout());
    sessionStorage.clear();
    localStorage.clear();
    void logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout, appLogout]);

  return <LoadingSpinner />;
};

export default Logout;
