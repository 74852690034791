import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'reactstrap';

import { useFetchStatistics } from '../../../../../api/StatisticsAPI';
import LevelBadge from '../../../../../components/LevelBadge';

const PrizeDetailsPerPlayer = ({ experienceId, label }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    fetchToken();
  }, [getAccessTokenSilently, setToken]);

  const { data: prizeDetailsPerPlayer } = useFetchStatistics(experienceId, 'prizeDetailsPerPlayer', token);

  return (
    <Container>
      <Table hover>
        <thead>
          <tr>
            <th className="orderable-column text-center" colSpan={3}>
              {label}
            </th>
          </tr>
          <tr>
            <th className="orderable-column text-center">Jugador</th>
            <th className="orderable-column text-center">Premio</th>
            <th className="orderable-column text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          {prizeDetailsPerPlayer &&
            prizeDetailsPerPlayer.map((d) =>
              d.prizes.sort(sorter).map((p, i) => {
                if (i === 0) {
                  return (
                    <tr key={d.id}>
                      <td rowSpan={d.prizes.length}>
                        <div>{d.full_name}</div>
                        <div>
                          <i>{d.team_name}</i>
                        </div>
                      </td>
                      <td>
                        <LevelBadge level={p.level} /> {p.name}
                      </td>
                      <td className="text-center">{p.count}</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <td>
                        <LevelBadge level={p.level} /> {p.name}
                      </td>
                      <td className="text-center">{p.count}</td>
                    </tr>
                  );
                }
              })
            )}
        </tbody>
      </Table>
    </Container>
  );
};

const sorter = (a, b) => {
  const diff = b.level - a.level;
  if (diff > 0) return 1;
  if (diff < 0) return -1;
  return b.name > a.name ? -1 : 1;
};

export default PrizeDetailsPerPlayer;
