import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonGroup, Container } from 'reactstrap';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useFetchJackpotList } from '../api/JackpotAPI';
import { useFetchPrizesList } from '../api/PrizesAPI';

import ExperienceList from './experience/ExperienceList';
import Loading from '../components/Loading';
import ReturnButton from '../components/ReturnButton';
import { useActor } from '../storage/storage';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const ExperienceMan = () => {
  const actor = useActor();
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { data: jackpotsList } = useFetchJackpotList();
  const { data: prizesList } = useFetchPrizesList(actor.company_id);

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const validate = () => {
    return jackpotsList && prizesList && jackpotsList.length > 0 && prizesList.length > 0;
  };

  const handleNavigation = (e) => {
    if (validate()) {
      navigate('new');
    } else {
      e.preventDefault();
      setShowMessage(true);
    }
  };

  return (
    <Container>
      <ButtonGroup>
        <ReturnButton to="/landing" />
        <button className="btn btn-primary" onClick={handleNavigation}>
          {t('buttons.add')}
        </button>
      </ButtonGroup>
      {showMessage && <div className="alert alert-danger">{t('content.missing')}</div>}
      <ExperienceList />
    </Container>
  );
};

export default withAuthenticationRequired(ExperienceMan, {
  onRedirecting: () => <Loading />,
});
