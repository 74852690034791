import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { isSameDay } from '../utils/date';
import { useCanOpenChest, useNextChestCountdown, useNextChestWorker } from '../api/ChestAPI';

import CountdownSpinner from './questionary/components/CountdownSpinner';

import '../assets/css/home.css';
import availableChest from '../assets/img/chest/chest-available-home.svg';
import { setExperience, useExperience, usePlayer } from '../storage/storage';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { fetchData } from '../api/axiosClient';
import { resetGame, resumeGame } from '../state/game';
import { BACKEND_URL } from '../utils/constants';
import ExperienceCarousel from './ExperienceCarousel';

let nextChestWorker;

const Home = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isChestAvailable = useCanOpenChest();
  const { game } = useSelector((state) => state.game);
  const { team } = useSelector((state) => state.team);

  const { createWorker } = useNextChestWorker();
  const { getNextChestCountdownComponent } = useNextChestCountdown();

  const [experienceId, setExperienceId] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(useExperience());
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const player = usePlayer();
  const playerId = player.player_id;

  useEffect(() => {
    void i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    const fetchExperience = async () => {
      if (!experienceId) return;
      setLoading(true);
      const url = `${BACKEND_URL}/experience/${experienceId}?lang=${i18n.resolvedLanguage}`;
      try {
        const experience = await fetchData(url, getAccessTokenSilently);
        setSelectedExperience(experience);
        setExperience(experience, dispatch);
      } catch (error) {
        console.error('There was an error fetching the experience!', error);
      } finally {
        setLoading(false);
      }
    };
    void fetchExperience();
  }, [experienceId, dispatch, getAccessTokenSilently, i18n]);

  useEffect(() => {
    const fetchGame = async () => {
      if (!selectedExperience) return;
      try {
        setLoading(true);
        const urlGame = `${BACKEND_URL}/game?playerId=${playerId}`;
        const data = await fetchData(urlGame, getAccessTokenSilently);
        setUpGame(data);
      } catch (error) {
        if (error.response.status === 404) {
          dispatch(resetGame(Array.from(selectedExperience.questions)));
        }
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const setUpGame = (gameData) => {
      const allQuestionIds = gameData.questions.map((question) => Object.keys(question).pop());
      const showedQuestions = new Set(allQuestionIds).size;
      const questions = Array.from(selectedExperience.questions);
      const randomIndex = Math.floor(Math.random() * questions.size);
      const gameOnCourse = {
        game: gameData,
        countAnswered: showedQuestions,
        question: questions[randomIndex],
        isPlayed: !!gameData.ended_at,
      };
      dispatch(resumeGame(gameOnCourse));
      sessionStorage.setItem('game', JSON.stringify(gameOnCourse));
    };
    fetchGame();
  }, [selectedExperience, dispatch, getAccessTokenSilently, playerId]);

  if (!nextChestWorker) nextChestWorker = createWorker();
  const hasBoostActive = team && team.boost_ended && new Date(team.boost_ended) > new Date();
  const isMissionCompleted = game && isSameDay(game.started_at);
  const timerClass = isMissionCompleted ? 'home-danger' : 'home-normal';
  const gameTime = isMissionCompleted ? '0:00' : hasBoostActive ? '3:00' : '2:00';

  const buildButton = () => {
    let innerText = t('game.home.start');
    let navigateTo = '/questionary';
    let buttonClass = 'primary';

    if (isChestAvailable) {
      buttonClass = 'open';
    }

    if (isMissionCompleted) {
      innerText = t('game.home.rewards');
      navigateTo = '/prize-catalog';
      buttonClass = 'open';
    }

    return (
      <button className={`gali-button ${buttonClass}`} onClick={() => navigate(navigateTo)}>
        {innerText}
      </button>
    );
  };

  return (
    <>
      <div className="home-container">
        <div className="experience-header">
          <div className="timer-container home">
            <CountdownSpinner style={timerClass} />
            <div className={`custom-timer-text ${timerClass}`}>
              {gameTime}
              {!isMissionCompleted ? <p>{t('game.home.playNow')}</p> : ''}
            </div>
          </div>
        </div>
        <div className="experience-body">
          <div className="experience-title">
            <h1>{t('game.home.mission')}</h1>
          </div>
          <ExperienceCarousel setExperienceId={setExperienceId} />
          <div className="experience-button">
            {isMissionCompleted && <span>{t('game.home.timeUp')}</span>}
            {buildButton()}
          </div>
        </div>
        <div className="experience-footer">
          {!isChestAvailable && getNextChestCountdownComponent(nextChestWorker, true)}
          {isChestAvailable && (
            <button className="gali-button home-chest-available" onClick={() => navigate('/chest')}>
              <div className="home-chest-available-right">
                <span className="first">{t('game.home.openChest')}</span>
                <span className="last">{t('game.home.daily')}</span>
              </div>
              <div className="home-chest-available-left">
                <img src={availableChest} alt="Chest Available" />
              </div>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
