import {
  Button,
  Form,
  FormGroup,
  Alert,
  Label,
  Col,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  Row,
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import ReturnButton from '../components/ReturnButton';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { LEVEL_OPTIONS, BACKEND_URL, PIECE_OPTIONS } from '../utils/constants';
import { validateImageFile } from '../utils/componentUtil';
import { applyHeaders } from '../api/axiosClient';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const EditPrize = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams();
  const [prize, setPrize] = useState(null);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [fetchError, setFetchError] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    const getPrize = async () => {
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/prize/${id}`, headers)
        .then((response) => setPrize(response.data))
        .catch((error) => setFetchError(error.response.data.message));
    };
    getPrize();
  }, [setFetchError, setPrize, id, getAccessTokenSilently]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrize({ ...prize, [name]: value });
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];
    const error = validateImageFile(file);
    if (error) {
      setImage(null);
      return setImageError(error);
    }
    setImage(file);
    setImageError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const updatePrize = new FormData();
    updatePrize.append('name', prize.name);
    updatePrize.append('total_pieces', prize.total_pieces);
    updatePrize.append('level', prize.level);
    updatePrize.append('image', image);
    if (prize.description) {
      updatePrize.append('description', prize.description);
    }
    const headers = await applyHeaders(getAccessTokenSilently, 'multipart/form-data');

    await axios
      .put(`${BACKEND_URL}/prize/${id}`, updatePrize, headers)
      .then(() => navigate('/prize'))
      .catch((e) => setImageError(e.response ? e.response.data.message : e.message))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {fetchError && <Alert color="danger">{fetchError}</Alert>}
      {imageError && <Alert color="danger">{imageError}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('catalogue.edit')}</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={5}>
                <Card id="prizeCard" style={{ width: '26rem' }}>
                  <CardHeader>
                    <FormGroup>
                      <Label className="admin-text" for="image">
                        {t('form.img')}
                      </Label>
                      <Input type="file" name="image" accept="image/*" id="image" onChange={handleImage} />
                    </FormGroup>
                  </CardHeader>
                  <CardBody>
                    {prize && (
                      <CardImg
                        id="image"
                        src={image ? URL.createObjectURL(image) : prize.url}
                        alt="Imagen premio"
                        style={{
                          width: '24rem',
                        }}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <FormGroup floating>
                  {prize && <Input id="name" name="name" type="text" value={prize.name} onChange={handleInputChange} />}
                  <Label className="admin-text" for="name">
                    {t('form.name')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  {prize && (
                    <Input
                      id="level"
                      name="level"
                      type="select"
                      defaultValue={-1}
                      value={prize.level}
                      onChange={handleInputChange}
                    >
                      <option />
                      {LEVEL_OPTIONS.map((o) => (
                        <option key={o.value} value={o.value}>
                          {t(o.label)}
                        </option>
                      ))}
                    </Input>
                  )}
                  <Label className="admin-text" for="level">
                    {t('catalogue.level')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  {prize && (
                    <Input
                      id="pieces"
                      name="total_pieces"
                      placeholder={t('catalogue.pieces')}
                      type="select"
                      value={prize.total_pieces}
                      onChange={handleInputChange}
                    >
                      <option />
                      {PIECE_OPTIONS.map((o) => (
                        <option key={o.value} value={o.value}>
                          {o.label}
                        </option>
                      ))}
                    </Input>
                  )}
                  <Label className="admin-text" for="pieces">
                    {t('catalogue.pieces')}
                  </Label>
                </FormGroup>
                <FormGroup floating>
                  {prize && (
                    <Input
                      id="description"
                      name="description"
                      type="textarea"
                      value={prize.description}
                      onChange={handleInputChange}
                      style={{
                        height: '8rem',
                        resize: 'none',
                      }}
                    />
                  )}
                  <Label className="admin-text" for="description">
                    {t('form.desc')}
                  </Label>
                </FormGroup>
                <FormGroup className="my-2">
                  <ReturnButton to={'/prize'} />
                  <Button color="primary" type="submit" disabled={loading}>
                    {t('buttons.ok')}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};
export default EditPrize;
