import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'reactstrap';

import { useFetchStatistics } from '../../../../../api/StatisticsAPI';

import PieChart from '../../charts/PieChart';
import DottedLineChart from '../../charts/DottedLineChart';

import styles from './styles/TotalCorrectAnswersPerTeam.module.css';

const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

function transform(data) {
  const transform = [];

  if (data && data.length > 0) {
    data.forEach((d) => {
      let team = transform.find((t) => t.id === d.teamId);
      if (!team) {
        team = {};
        team.id = d.teamId;
        team.name = d.name;
        team.series = [];
        transform.push(team);
      }
      const item = {
        month: MONTHS[d.month - 1],
        gameCount: d.gameCount,
        totalQuestions: d.totalQuestions,
        teamScore: d.teamScore,
        percentage: ((d.teamScore * 100) / d.totalQuestions).toFixed(2),
      };

      team.series.push(item);
    });
  }
  return transform;
}

const lineChartData = (data) =>
  data.series.map((value) => {
    return { label: value.month, value: value.percentage };
  });
const pieChartData = (data) => {
  const wrongCount = data.totalQuestions - data.teamScore;

  return [
    { label: data.teamScore, value: data.teamScore },
    { label: wrongCount, value: wrongCount },
    { month: `${data.percentage}` },
  ];
};

const TotalCorrectAnswersPerTeam = ({ experienceId, label }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    fetchToken();
  }, [getAccessTokenSilently, setToken]);

  const { data } = useFetchStatistics(experienceId, 'totalCorrectAnswersPerTeam', token);

  const answersPerTeam = transform(data);

  const chartColors = ['#71c89e', '#6eafd5'];
  const chartDimensions = { width: 200, height: 200 };
  const chartLabels = { x: 'Mes', y: '%' };

  return (
    <Container>
      <Table striped>
        <thead>
          <tr key="title">
            <th className="orderable-column text-center" colSpan={5}>
              {label}
            </th>
          </tr>
          <tr key="key">
            <th className="orderable-column" colSpan={5}>
              <div>
                <span className={`${styles.box} ${styles.green}`}>&nbsp;</span>
                &nbsp;
                <span>Correctas</span>&nbsp;
                <span className={`${styles.box} ${styles.blue}`}>&nbsp;</span>
                &nbsp;
                <span>Incorrectas</span>&nbsp;
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {answersPerTeam && answersPerTeam.length > 0 ? (
            answersPerTeam.map((team) => (
              <tr key={team.name}>
                <td key={1}>{team.name}</td>
                <td key={team.name}>
                  <DottedLineChart data={lineChartData(team)} labels={chartLabels} dimensions={chartDimensions} />
                </td>
                {team.series.map((item) => (
                  <td style={{ textAlign: 'center' }}>
                    <PieChart data={pieChartData(item)} colors={chartColors} dimensions={chartDimensions} />
                    <b>{item.month}</b>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td>No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default TotalCorrectAnswersPerTeam;
