const formatDate = (input) => {
  let theDate = new Date(input);
  const date = theDate.toISOString().split('T')[0];
  const time = theDate.toLocaleTimeString('es-CO', { timeZone: getTimezone() });
  return `${date} ${time}`;
};

const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const isSameDay = (input) => {
  const date = new Date(input);
  const now = new Date();
  return (
    date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()
  );
};

const formatDateTimeWithSpanBreak = (input) => {
  let theDate = new Date(input);
  const date = theDate.toISOString().split('T')[0];
  const time = theDate.toLocaleTimeString('es-CO', { timeZone: getTimezone() });
  return (
    <span>
      {date}
      <br />
      {time}
    </span>
  );
};

const formatDateTime = (input) => {
  const theDate = new Date(input);
  const date = theDate.toLocaleDateString('es-CO', { timeZone: getTimezone() });
  const time = theDate.toLocaleTimeString('es-CO', { timeZone: getTimezone() });
  return (
    <span>
      {date} {time}
    </span>
  );
};

const isExpired = (input) => {
  return isBefore(input, 0);
};

const isBefore = (input, intervalInMillis) => {
  const now = new Date();
  const nowFixed = now.getTime() - now.getTimezoneOffset() * 60000 - intervalInMillis;
  const date = new Date(input);
  const dateFixed = date.getTime() - date.getTimezoneOffset() * 60000;
  return dateFixed < nowFixed;
};

const formatInterval = (interval, t) => {
  if (interval.days > 0) return `${interval.days} ${t('time.days')}`;
  if (interval.hours > 0) return `${interval.hours} ${t('time.hours')}`;
  if (interval.minutes > 0) return `${interval.minutes} ${t('time.min')}`;

  return '';
};

const isAWeekAgo = (input) => {
  const aWeekInMillis = 7 * 24 * 60 * 60 * 1000;
  return isBefore(input, aWeekInMillis);
};

export {
  getTimezone,
  formatDate,
  isSameDay,
  formatDateTimeWithSpanBreak,
  formatDateTime,
  formatInterval,
  isExpired,
  isAWeekAgo,
};
