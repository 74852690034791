import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prizes: [],
};

const prizesSlice = createSlice({
  name: 'prizes',
  initialState,
  reducers: {
    initPrizes: (state, action) => {
      state.prizes = action.payload;
    },
  },
});

export const { initPrizes } = prizesSlice.actions;
export const selectPrizes = (state) => state.prizes;
export default prizesSlice.reducer;
