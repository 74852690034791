import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BACKEND_URL } from '../../utils/constants';
import { applyHeaders } from '../../api/axiosClient';
import { useAuth0 } from '@auth0/auth0-react';

import lowStreakImg from '../../assets/img/profile/low-streak.png';
import highStreakImg from '../../assets/img/profile/high-streak.png';
import timeImg from '../../assets/img/profile/time.png';
import checkboxImg from '../../assets/img/profile/answer-check.png';
import chestImg from '../../assets/img/chest.png';

import '../../assets/css/profile/profile-stats.css';
import { useExperience, usePlayer } from '../../storage/storage';
import '../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';

const Stats = () => {
  const experience = useExperience();
  const player = usePlayer();

  const { getAccessTokenSilently } = useAuth0();
  const [modules, setModules] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (!player || !experience) return;

    const getStats = async () => {
      try {
        const url = `${BACKEND_URL}/experience/${experience.experience_id}/player/${player.player_id}/stats`;
        const headers = await applyHeaders(getAccessTokenSilently);
        const res = await axios.get(url, headers);

        const stats = [
          {
            image: res.data.game_streak > 5 ? highStreakImg : lowStreakImg,
            title: t('game.profile.stats.streak'),
            value: res.data.game_streak,
          },
          {
            image: checkboxImg,
            title: t('game.profile.stats.hits'),
            value: `${res.data.performance}`,
          },
          {
            image: chestImg,
            title: t('game.profile.stats.openChest'),
            value: `${res.data.chest_openings}`,
          },
          {
            image: timeImg,
            title: t('game.profile.stats.timePlayed'),
            value: `${res.data.played_time}`,
          },
        ];

        setModules(stats);
      } catch (e) {
        setModules([]);
        console.error(e);
      }
    };

    getStats();
  }, [experience, player, setModules]);

  return (
    modules.length > 0 && (
      <div className="sub-container">
        <h1>{t('game.profile.stats.name')}</h1>
        <div className="stats-box">
          {modules.map((item, idx) => (
            <div key={idx} className="statsItem">
              <div className="statsItem-img">
                <img src={item.image} alt="item image" />
              </div>
              <div className="statsItem-text">
                <span className="statsItem-value">{item.value}</span>
                <span className="statsItem-title">{item.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default Stats;
