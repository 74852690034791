import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  player: null,
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    initPlayer: (state, action) => {
      state.player = action.payload;
    },
  },
});

export const { initPlayer } = playerSlice.actions;
export const selectPlayer = (state) => state.player;
export default playerSlice.reducer;
