import { Navigate, Outlet } from 'react-router-dom';
import { useAppAuthenticated } from '../storage/storage';

const UnprotectedRoute = (props) => {
  const isAppAuthenticated = useAppAuthenticated();

  if (isAppAuthenticated) return <Navigate to="/landing" />;

  return <Outlet {...props} />;
};

export default UnprotectedRoute;
