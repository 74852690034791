import { useEffect, useState } from 'react';
import { isSameDay } from '../../utils/date';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePatchInventory, useUpdateInventoryContext } from '../../api/InventoryAPI';
import { useCanOpenChest, useNextChestCountdown, useNextChestWorker } from '../../api/ChestAPI';

import openChest from '../../assets/img/chest/chest-opened.svg';
import closedChest from '../../assets/img/chest/chest-available.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import '../../assets/css/chest.css';
import Carousel from './Carousel';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

let nextChestWorker;

const DailyChest = () => {
  const { game } = useSelector((state) => state.game);
  const { response, generateDailyChest } = usePatchInventory();
  const { createWorker } = useNextChestWorker();
  const { updateInventoryContext } = useUpdateInventoryContext();
  const { getNextChestCountdownComponent } = useNextChestCountdown();

  const [isChestOpen, setIsChestOpen] = useState(!useCanOpenChest());
  const [showCarousel, setShowCarousel] = useState(false);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);
  const chestImage = isChestOpen ? openChest : closedChest;
  const gameStorage = sessionStorage.getItem('game');
  const gameStartedAt = game ? game.started_at : gameStorage ? JSON.parse(gameStorage).started_at : undefined;
  const isMissionCompleted = gameStartedAt && isSameDay(gameStartedAt);

  if (!nextChestWorker) nextChestWorker = createWorker();

  const handleButtonClick = () => {
    if (!isChestOpen) {
      openDailyChest();
      return;
    }
    const moveTo = isMissionCompleted ? '/prize-catalog' : '/questionary';
    navigate(moveTo);
  };

  const openDailyChest = async () => {
    try {
      await generateDailyChest();
      await updateInventoryContext();
      setIsChestOpen(true);
      setShowCarousel(true);
      nextChestWorker = createWorker();
    } catch (error) {
      console.error('Error opening chest:', error);
      setIsChestOpen(false);
      setShowCarousel(false);
    }
  };

  const handleCollectRewards = () => {
    setShowCarousel(false);
  };

  const determineClass = () => {
    if (!isChestOpen) return 'gali-button primary';
    if (isMissionCompleted) return 'button-rewards';
    return 'gali-button open';
  };

  const determineName = () => {
    if (!isChestOpen) return t('game.home.openChest');
    if (!isMissionCompleted) return t('game.home.start');
    return t('game.home.explore');
  };

  if (showCarousel && response) return <Carousel handleCollectRewards={handleCollectRewards} rewards={response} />;

  return (
    <div className={`img-chest-container ${!isChestOpen ? 'can-open' : ''}`}>
      {isChestOpen && getNextChestCountdownComponent(nextChestWorker, false)}
      <img src={chestImage} className="img-chest" alt="chest" />
      <button className={determineClass()} onClick={handleButtonClick}>
        {isChestOpen && isMissionCompleted && <FontAwesomeIcon icon={faArrowRight} className="rewards-icon" />}
        {determineName()}
      </button>
    </div>
  );
};

export default DailyChest;
