import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const ReturnButton = ({ to }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <Button color="secondary" onClick={() => navigate(to)}>
      {t('buttons.back')}
    </Button>
  );
};

export default ReturnButton;
