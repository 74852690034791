import React from 'react';
import Loading from '../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import InviteActor from './InviteActor';

const InviteSupreme = () => <InviteActor rol={'SUPREME'} navigateTo={'landing'} />;

export default withAuthenticationRequired(InviteSupreme, {
  onRedirecting: () => <Loading />,
});
