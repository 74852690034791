import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import gameReducer from './game';
import experienceReducer from './experience';
import inventoryReducer from './inventory';
import teamReducer from './team';
import playerReducer from './player';
import companyReducer from './company';
import prizesReducer from './prizes';
import jackpotReducer from './jackpot';

const store = configureStore({
  reducer: {
    auth: authReducer,
    experience: experienceReducer,
    inventory: inventoryReducer,
    game: gameReducer,
    team: teamReducer,
    player: playerReducer,
    company: companyReducer,
    prizes: prizesReducer,
    jackpot: jackpotReducer,
  },
});

export default store;
