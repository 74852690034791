const CountdownWorker = () => {
  const STANDARD_GAME_TIME = 2;
  const BOOSTED_GAME_TIME = 3;
  const DANGER_LIMIT_IN_SECS = 15;
  const WARNING_LIMIT_IN_SECS = 60;

  let countdownInterval;
  let initCountdown;
  let gameEndsAt;

  onmessage = function (event) {
    if (event.data.clear) {
      clearInterval(countdownInterval);
      return;
    }
    initialize(event.data);
  };

  const initialize = (game) => {
    const runtime = game.boosted ? BOOSTED_GAME_TIME : STANDARD_GAME_TIME;
    initCountdown = runtime * 60;
    gameEndsAt = new Date(game.started_at);
    gameEndsAt.setSeconds(gameEndsAt.getSeconds() + initCountdown);

    countdownInterval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    calculateRemainingTime();
  };

  const calculateRemainingTime = () => {
    if (!gameEndsAt) return;

    const now = new Date();
    const nowFixed = now.getTime() - now.getTimezoneOffset() * 60000;
    const diff = gameEndsAt.getTime() - gameEndsAt.getTimezoneOffset() * 60000 - nowFixed;

    const getColorStyle = () => {
      const secs = parseInt(diff / 1000);
      if (secs <= DANGER_LIMIT_IN_SECS) return 'danger';
      if (secs <= WARNING_LIMIT_IN_SECS) return 'warning';
      return 'game-normal';
    };

    let message = {
      remainingTime: '00:00',
      colorStyle: 'danger',
    };

    if (diff > 0) {
      let minutes = Math.floor((diff % 3.6e6) / 6e4);
      let seconds = Math.floor((diff % 6e4) / 1000);
      message = {
        remainingTime: `0${!minutes ? '0' : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`,
        ended: false,
        colorStyle: getColorStyle(),
      };
    } else {
      message = {
        ...message,
        ended: true,
      };
    }

    postMessage(message);
  };
};

export default CountdownWorker;
