// External Libraries
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Container, Table } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import DrawModal from './components/DrawModal';
import RemainingPrizesModal from './components/RemainingPrizesModal';

// Internal Utilities and Constants
import { getSortIcon, handleSort } from '../stats/sorting/Sorting';

// API hooks
import { useFetchExperienceList } from '../../api/ExperiencesAPI';
import { useActor } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';

const ExperienceList = () => {
  const actor = useActor();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: 'descending',
  });
  const { experienceList, setExperienceList, loading, error } = useFetchExperienceList(actor.company_id);

  const onSort = (key) => {
    const { sortedData, direction } = handleSort(experienceList, {
      key,
      direction: sortConfig.direction,
    });
    setExperienceList(sortedData);
    setSortConfig({ key, direction });
  };

  return (
    <Container>
      <Card>
        <CardHeader className="admin-text" tag={'h4'}>
          {t('menu.content')}
        </CardHeader>
        <CardBody>
          {!experienceList && loading && <Loading />}
          {!experienceList && error && <div>{t('tables.error')}</div>}
          {!experienceList && <Alert color="light">{t('tables.empty')}</Alert>}
          {experienceList && (
            <Table striped>
              <thead>
                <tr>
                  <th className="text-center experiences__column-name" onClick={() => onSort('name')}>
                    {t('tables.name')} {getSortIcon(sortConfig, 'name')}
                  </th>
                  <th className="experiences__column-questions" onClick={() => onSort('questions')}>
                    {t('content.questionsCount')} {getSortIcon(sortConfig, 'questions')}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {experienceList.map((row) => (
                  <tr key={row.experience_id}>
                    <td className="text-center experiences__column-name">
                      <Link to={`/experience/${row.experience_id}`}>
                        {row.name[i18n.resolvedLanguage] ? row.name[i18n.resolvedLanguage] : row.name['en']}
                      </Link>
                    </td>
                    <td className="experiences__column-questions">{row.questions}</td>
                    <td className="text-center">
                      <RemainingPrizesModal id={row.experience_id}></RemainingPrizesModal>
                      <DrawModal id={row.experience_id}>{t('content.launchDraw')}</DrawModal>
                      <Button color="link" size="sm" onClick={() => navigate(`/experience/${row.experience_id}/stats`)}>
                        {t('content.stats')}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default ExperienceList;
