import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  game: null,
  countAnswered: 0,
  isPlayed: false,
  question: null,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    resumeGame: (state, action) => {
      state.game = action.payload.game;
      state.countAnswered = action.payload.countAnswered;
      state.isPlayed = action.payload.isPlayed;
      state.question = action.payload.question;
    },
    initGame: (state, action) => {
      state.game = action.payload.game;
      state.isPlayed = false;
      state.countAnswered = 0;
      state.question = action.payload.question;
    },
    resetGame: (state) => {
      state.game = null;
      state.isPlayed = false;
      state.countAnswered = 0;
    },
    refreshQuestions: (state, action) => {
      state.question = action.payload.question;
      state.countAnswered = state.countAnswered + 1;
    },
    markAsPlayed: (state) => {
      state.isPlayed = true;
    },
  },
});

export const { resumeGame, initGame, resetGame, markAsPlayed, refreshQuestions } = gameSlice.actions;
export const selectGame = (state) => state.game;
export default gameSlice.reducer;
