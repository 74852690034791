import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'reactstrap';

import { useFetchStatistics } from '../../../../../api/StatisticsAPI';

import BarChart from '../../charts/BarChart';
import getColors from '../../../utils/coloring';

const TotalGameTimePerTeam = ({ experienceId, label }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    fetchToken();
  }, [getAccessTokenSilently, setToken]);

  const { data: timePerTeam } = useFetchStatistics(experienceId, 'totalGameTimePerTeam', token);

  const chartColors = timePerTeam && getColors(timePerTeam.map((t) => t.name));
  const chartDimensions = { width: 900, height: 500 };
  const chartLabels = { x: 'Equipo', y: '# Horas' };
  const chartData =
    timePerTeam &&
    timePerTeam.map((d) => {
      return {
        label: d.name,
        value: d.total,
      };
    });

  return (
    <Container>
      <Table striped>
        <thead>
          <tr key="header">
            <th className="orderable-column text-center" colSpan={2}>
              {label}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="content-center">
              {timePerTeam && timePerTeam.length > 0 ? (
                <BarChart data={chartData} labels={chartLabels} colors={chartColors} dimensions={chartDimensions} />
              ) : (
                'No hay datos disponibles'
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default TotalGameTimePerTeam;
