import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'reactstrap';

import { useFetchStatistics } from '../../../../../api/StatisticsAPI';

import BarChart from '../../charts/BarChart';

const TotalPrizeBoostsPerTeam = ({ experienceId, label }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    fetchToken();
  }, [getAccessTokenSilently, setToken]);

  const { data: boostsPerTeam } = useFetchStatistics(experienceId, 'totalPrizeBoostsPerTeam', token);

  const chartColors = ['#ffd700', '#c0c0c0', '#cd7f32'];
  const chartDimensions = { width: 300, height: 300 };
  const chartLabels = { x: 'Nivel', y: '# Boosts' };
  const chartData = (boosts) => {
    return [
      { label: 'Oro', value: boosts['2'] },
      { label: 'Plata', value: boosts['1'] },
      { label: 'Bronce', value: boosts['0'] },
    ];
  };

  return (
    <Container>
      <Table striped>
        <thead>
          <tr key="title">
            <th className="orderable-column text-center" colSpan={2}>
              {label}
            </th>
          </tr>
        </thead>
        <tbody>
          {boostsPerTeam && boostsPerTeam.length > 0 ? (
            boostsPerTeam.map((team) => (
              <tr key={team.name}>
                <td key={1}>{team.name}</td>
                <td className="content-center" key={2}>
                  <BarChart
                    data={chartData(team)}
                    labels={chartLabels}
                    colors={chartColors}
                    dimensions={chartDimensions}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default TotalPrizeBoostsPerTeam;
