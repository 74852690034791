import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const BarChart = ({ data, labels, colors, dimensions, scale = 'units' }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (!d3Container.current) return;

    // Clear previous chart
    d3.select(d3Container.current).selectAll('*').remove();

    const { width, height } = dimensions;
    const margin = { top: 30, right: 30, bottom: 100, left: 50 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3
      .select(d3Container.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.label))
      .range([0, innerWidth])
      .padding(0.4);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .nice()
      .range([innerHeight, 0]);

    const yAxis = d3.axisLeft(y).tickFormat(scale === 'units' ? d3.format('d') : d3.format('.2f'));

    svg.append('g').call(yAxis);

    const xAxis = svg.append('g').attr('transform', `translate(0,${innerHeight})`).call(d3.axisBottom(x));

    let xAxisLabelFactor = 0.7;
    if (rotateLabels(data)) {
      xAxis.selectAll('text').attr('transform', 'rotate(-30)').style('text-anchor', 'end');
      xAxisLabelFactor = 0.9;
    }

    // Add rectangles
    svg
      .selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => x(d.label))
      .attr('y', innerHeight)
      .attr('width', x.bandwidth())
      .attr('height', 0)
      .attr('fill', (d, i) => colors[i % colors.length])
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .transition()
      .duration(1000)
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => (d.value === 0 ? 0 : innerHeight - y(d.value)));

    // Add labels
    svg
      .selectAll('.label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'label')
      .attr('x', (d) => x(d.label) + x.bandwidth() / 2)
      .attr('y', (d) => (d.value === 0 ? innerHeight : y(d.value)) - 10)
      .attr('text-anchor', 'middle')
      .text((d) => d.value)
      .style('fill', 'black');

    // X-axis label
    svg
      .append('text')
      .attr('class', 'x-axis-label')
      .attr('text-anchor', 'middle')
      .attr('x', innerWidth / 2)
      .attr('y', xAxisLabelFactor * height)
      .text(labels.x);

    // Y-axis label
    svg
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('text-anchor', 'middle')
      .attr('x', -height / 2 + margin.top)
      .attr('y', -25)
      .attr('transform', 'rotate(-90)')
      .text(labels.y);
  });

  return <svg className="d3-component" ref={d3Container} />;
};

const rotateLabels = (data) => {
  if (!data) return false;
  const max = data.reduce((a, v) => (v.label.length > a ? v.label.length : a), 0);
  if (data.length > 3) {
    return max > 7;
  } else {
    return max > 15;
  }
};

export default BarChart;
