import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  company: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    initCompany: (state, action) => {
      state.company = action.payload;
    },
  },
});

export const { initCompany } = companySlice.actions;
export const selectCompany = (state) => state.company;
export default companySlice.reducer;
