import React, { useEffect } from 'react';
import { Input, Table } from 'reactstrap';

import { useFetchPrizesList } from '../../../api/PrizesAPI';
import { getLevelLabel, LEVEL_COLORS } from '../../../utils/constants';
import { useActor } from '../../../storage/storage';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/Loading';

const PrizesTable = ({ selectedPrizes, setSelectedPrizes }) => {
  const actor = useActor();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const { data: prizesList, loading, error } = useFetchPrizesList(actor.company_id);

  const handleAmounts = (event) => {
    const prize_id = event.target.name;
    const amount = parseInt(event.target.value);

    if (selectedPrizes[prize_id]) {
      if (amount > 0) {
        selectedPrizes[prize_id] = amount;
      } else {
        delete selectedPrizes[prize_id];
      }
    } else {
      if (amount > 0) {
        selectedPrizes[prize_id] = amount;
      }
    }
    setSelectedPrizes({ ...selectedPrizes });
  };

  return (
    <Table hover responsive size="sm" striped className="text-center">
      <thead>
        <tr key="header">
          <th className="prizes__column-name">{t('tables.name')}</th>
          <th className="prizes__column-nivel">{t('catalogue.level')}</th>
          <th className="prizes__column-amount">{t('tables.quantity')}</th>
        </tr>
      </thead>
      <tbody>
        {prizesList &&
          prizesList.map((aPrize) => (
            <tr key={aPrize.prize_id}>
              <td className="prizes__column-name">{aPrize.name}</td>
              <td className="prizes__column-nivel">
                <span class={`badge ${LEVEL_COLORS[aPrize.level]}`}>{t(getLevelLabel(aPrize.level))}</span>
              </td>
              <td className="content-right">
                <Input
                  bsSize="sm"
                  className="mb-3"
                  type="number"
                  name={aPrize.prize_id}
                  min="0"
                  defaultValue="0"
                  onChange={handleAmounts}
                />
              </td>
            </tr>
          ))}
        {!prizesList && loading && (
          <tr>
            <td colSpan={4}>
              <Loading />
            </td>
          </tr>
        )}
        {!prizesList && error && (
          <tr>
            <td colSpan={4}>
              {t('tables.error')} {error.message}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default PrizesTable;
