import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'reactstrap';

import { useFetchStatistics } from '../../../../../api/StatisticsAPI';

import BarChart from '../../charts/BarChart';
import getColors from '../../../utils/coloring';

const TotalGameDaysPerTeam = ({ experienceId, label }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    fetchToken();
  }, [getAccessTokenSilently, setToken]);

  const { data: daysPerTeam } = useFetchStatistics(experienceId, 'totalGameDaysPerTeam', token);

  const chartColors = daysPerTeam && getColors(daysPerTeam.map((t) => t.name));
  const chartDimensions = { width: 900, height: 500 };
  const chartLabels = { x: 'Equipo', y: '# Dias' };
  const chartData =
    daysPerTeam &&
    daysPerTeam.map((d) => {
      return {
        label: d.name,
        value: d.total,
      };
    });

  return (
    <Container>
      <Table striped>
        <thead>
          <tr key="header">
            <th className="orderable-column text-center" colSpan={2}>
              {label}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="content-center">
              {daysPerTeam && daysPerTeam.length > 0 ? (
                <BarChart
                  data={chartData}
                  labels={chartLabels}
                  colors={chartColors}
                  dimensions={chartDimensions}
                  scale={'units'}
                />
              ) : (
                'No hay datos disponibles'
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default TotalGameDaysPerTeam;
