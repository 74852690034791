import { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Table, FormGroup, Alert, Button, ButtonGroup } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { BACKEND_URL, LEVEL_COLORS, LEVEL_NAMES } from '../../utils/constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import Loading from '../../components/Loading';
import ReturnButton from '../../components/ReturnButton';
import { applyHeaders, patchData } from '../../api/axiosClient';
import { useActor } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const TeamList = () => {
  const { t, i18n } = useTranslation();
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const actor = useActor();
  const [teamList, setTeamList] = useState([]);
  const [state, setState] = useState({
    success: null,
    error: null,
    redirect: false,
  });

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    const getTeamList = async () => {
      const url =
        actor.rol === 'SUPREME_LEADER'
          ? `${BACKEND_URL}/company/${actor.company_id}/team`
          : `${BACKEND_URL}/team?actorId=${actor.actor_id}`;

      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(url, headers)
        .then((response) => setTeamList(response.data.data))
        .catch((error) => {
          if (error.response.status === 404) {
            setTeamList(null);
            return;
          }
          if (error.response.status === 401) {
            setState({
              ...state,
              redirect: true,
            });
            return;
          }
          setState({
            ...state,
            error: error.response.data.message,
          });
        });
    };
    getTeamList();
  }, [getAccessTokenSilently, actor, setState, state]);

  const editMetric = (teamId, event) => {
    const teamIndex = teamList.findIndex((t) => t.team_id === teamId);
    const team = JSON.parse(JSON.stringify(teamList[teamIndex]));
    if (!team.metrics) team.metrics = {};
    team.metrics.tips = event.target.value;
    const newTeamList = JSON.parse(JSON.stringify(teamList));
    newTeamList[teamIndex] = team;
    setTeamList(newTeamList);
  };

  const save = async (teamId, event) => {
    event.target.disabled = true;
    setState((previous) => ({ ...previous, success: null, error: null }));
    const url = `${BACKEND_URL}/team/metrics`;
    const team = teamList.find((t) => t.team_id === teamId);
    const data = { teamId: team.team_id, metrics: team.metrics };
    patchData(url, data, getAccessTokenSilently)
      .then(() => setState((previous) => ({ ...previous, success: true, error: null })))
      .catch((error) => {
        console.error(error);
        setState((previous) => ({ ...previous, error: error.message }));
      })
      .finally(() => {
        event.target.disabled = false;
      });
  };

  if (error) return <p>{error.message}</p>;

  if (isLoading) return <Loading />;

  if (state.redirect) return <Navigate to="/landing" />;

  return (
    <div>
      {state.error && <Alert color="danger">{t('boostRequest.error')}</Alert>}
      {state.success && <Alert color="success">{t('metrics.success')}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.myTeam')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            {!teamList && (
              <NavLink className="btn btn-primary" to="new">
                {t('buttons.add')}
              </NavLink>
            )}
          </ButtonGroup>
          <FormGroup row>{!teamList && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

          {teamList && (
            <Table hover borderless responsive size="sm" striped className="text-center">
              <thead>
                <tr>
                  <th>{t('tables.name')}</th>
                  <th>{t('catalogue.level')}</th>
                  {actor.rol !== 'SUPREME_LEADER' && <th colSpan={2}>{t('tables.actions')}</th>}
                  {actor.rol === 'SUPREME_LEADER' && <th>{t('metrics.title')}</th>}
                </tr>
              </thead>
              <tbody>
                {teamList.map((team) => (
                  <tr key={team.name}>
                    <td>{team.name}</td>
                    <td>
                      <span className={`badge ${LEVEL_COLORS[team.prize_level]}`}>
                        {t(LEVEL_NAMES[team.prize_level])}
                      </span>
                    </td>
                    {actor.rol === 'SUPREME_LEADER' && (
                      <td>
                        <div>
                          <label>{t('metrics.tips')}</label>&nbsp;
                          <input
                            type="number"
                            min="0"
                            value={team.metrics?.tips}
                            onChange={(e) => editMetric(team.team_id, e)}
                          />
                          <Button color="primary" onClick={(e) => save(team.team_id, e)}>
                            {t('buttons.ok')}
                          </Button>
                        </div>
                      </td>
                    )}
                    {actor.rol === 'LEADER,PLAYER' && (
                      <td>
                        <Button
                          color="link"
                          title="Ver jugadores"
                          size="sm"
                          onClick={() => navigate(`${team.team_id}`)}
                        >
                          <FontAwesomeIcon icon={faPeopleGroup} className="faWhite" />
                        </Button>
                        <Button
                          color="link"
                          title="Invitar"
                          size="sm"
                          onClick={() => navigate(`${team.team_id}/invite`)}
                        >
                          <FontAwesomeIcon icon={faUserPlus} className="faWhite" />
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(TeamList, {
  onRedirecting: () => <Loading />,
});
