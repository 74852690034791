import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'reactstrap';

import { useFetchStatistics } from '../../../../../api/StatisticsAPI';

import BarChart from '../../charts/BarChart';
import getColors from '../../../utils/coloring';

const TotalPrizesRedeemedPerTeam = ({ experienceId, label }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    fetchToken();
  }, [getAccessTokenSilently, setToken]);

  const { data: prizesPerTeam } = useFetchStatistics(experienceId, 'totalPrizesRedeemedPerTeam', token);

  const chartDimensions = { width: 1000, height: 300 };
  const chartLabels = { x: 'Premios', y: '# Premios' };
  const chartData = (object) => {
    return Object.entries(object).map((entry) => {
      return { label: entry[0], value: entry[1] };
    });
  };

  return (
    <Container>
      <Table striped>
        <thead>
          <tr key="title">
            <th className="orderable-column text-center" colSpan={2}>
              {label}
            </th>
          </tr>
        </thead>
        <tbody>
          {prizesPerTeam && prizesPerTeam.length > 0 ? (
            prizesPerTeam.map((team) => (
              <tr key={team.teamName}>
                <td key={1}>{team.teamName}</td>
                <td className="content-center" key={2}>
                  <BarChart
                    data={chartData(team.prizes)}
                    labels={chartLabels}
                    colors={getColors(Object.keys(team.prizes))}
                    dimensions={chartDimensions}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td>No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default TotalPrizesRedeemedPerTeam;
