import { createSlice } from '@reduxjs/toolkit';

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    inventory: null,
  },
  reducers: {
    initInventory: (state, action) => {
      state.inventory = action.payload;
    },
  },
});

export const { initInventory } = inventorySlice.actions;
export const selectInventory = (state) => state.inventory;
export default inventorySlice.reducer;
