import { Outlet, Navigate } from 'react-router-dom';
import { hasRole } from '../utils/roleUtil';
import { useActor, useAppAuthenticated } from '../storage/storage';

const ProtectedRoute = ({ rol, props }) => {
  const actor = useActor();
  const isAppAuthenticated = useAppAuthenticated();

  if (!isAppAuthenticated) return <Navigate to="/" />;
  if (!hasRole(actor, rol)) return <Navigate to="/landing" />;

  return <Outlet {...props} />;
};

export default ProtectedRoute;
