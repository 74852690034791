import GameContainer from './GameContainer';
import PlayerProfile from './PlayerProfile';

const GamePlayerProfile = () => {
  return (
    <GameContainer active={'GamePlayerProfile'}>
      <PlayerProfile />
    </GameContainer>
  );
};

export default GamePlayerProfile;
