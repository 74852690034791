// External Libraries
import React, { useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import { useParams } from 'react-router-dom';

// API Hooks
import { useFetchExperience } from '../../api/ExperiencesAPI';

// Assets (Fonts, Images, CSS, SFX)
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReturnButton from '../../components/ReturnButton';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';

const ExperienceDetail = () => {
  const { id: experienceId } = useParams();
  const { experience, loading, error } = useFetchExperience(experienceId);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const isEmpty = (object) => {
    if (Array.isArray(object) && object.length === 0) return true;
    return typeof object === 'object' && Object.keys(object).length === 0;
  };

  return (
    <Container>
      <ReturnButton to={'/experience'} />
      <Card>
        {experience && (
          <CardHeader tag={'h4'}>
            <Row>
              <Col className="content-left admin-text">{experience.name}</Col>
              <Col className="content-right">
                {experience.active ? (
                  <div className="text-enabled">{t('content.active')}</div>
                ) : (
                  <div className="text-disabled">{t('content.inactive')}</div>
                )}
              </Col>
            </Row>
          </CardHeader>
        )}
        <CardBody>
          {!isEmpty(experience) && (
            <Table striped>
              <thead>
                <tr>
                  <th>{t('content.question')}</th>
                  <th>{t('form.img')}</th>
                  <th>{t('content.answers')}</th>
                  <th>{t('content.correctAns')}</th>
                </tr>
              </thead>
              <tbody>
                {experience.questions.map((question, index) => (
                  <tr key={index}>
                    {question.text ? (
                      <td>{question.text}</td>
                    ) : (
                      <td className="text-center">
                        <FontAwesomeIcon icon={faXmark} className="faRed" />
                      </td>
                    )}
                    {question.image ? (
                      <td>
                        <img className="experience" src={question.image} alt="" />
                      </td>
                    ) : (
                      <td className="text-center">
                        <FontAwesomeIcon icon={faXmark} className="faRed" />
                      </td>
                    )}
                    <td>
                      {question.options.map((option) => (
                        <div key={option.id}>- {option.text}.</div>
                      ))}
                    </td>
                    <td>
                      {question.options
                        .filter((option) => option.id === question.validAnswerOptionId)
                        .map((option) => (
                          <div>
                            {option.text}&nbsp;
                            <FontAwesomeIcon icon={faCheck} className="faGreen" />
                          </div>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {isEmpty(experience) && loading && <Loading />}
          {isEmpty(experience) && error && <div>{t('tables.error')}</div>}
        </CardBody>
      </Card>
    </Container>
  );
};

export default ExperienceDetail;
