import React from 'react';
import LoadingSpinner from '../client/questionary/components/LoadingSpinner';

const Loading = () => (
  <div className="spinner">
    <LoadingSpinner />
  </div>
);

export default Loading;
