import React, { Fragment } from 'react';

import Init from '../components/Init';
import { useAuth0 } from '@auth0/auth0-react';

const Home = () => {
  const { isAuthenticated } = useAuth0();
  return <Fragment>{!isAuthenticated && <Init />}</Fragment>;
};

export default Home;
