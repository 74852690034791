import { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { Table, FormGroup, Alert, Button, ButtonGroup } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { BACKEND_URL, DECIMALS_REGEXP, LEVEL_COLORS, LEVEL_NAMES } from '../../utils/constants';

import Loading from '../../components/Loading';
import ReturnButton from '../../components/ReturnButton';
import { applyHeaders, patchData } from '../../api/axiosClient';
import { useActor } from '../../storage/storage';
import { useTranslation } from 'react-i18next';

import '../../i18n/i18n';

const initialState = {
  success: null,
  error: null,
  redirect: false,
};
const MetricsList = () => {
  const actor = useActor();
  const { t, i18n } = useTranslation();
  const { isLoading, error, getAccessTokenSilently } = useAuth0();

  const [metricsList, setMetricsList] = useState([]);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    const getMetricsList = async () => {
      const url = `${BACKEND_URL}/company/${actor.company_id}`;
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(url, headers)
        .then((response) => {
          setMetricsList(response.data.metrics);
        })
        .catch((error) => {
          setState({
            ...state,
            error: error.response.data.message,
          });
        });
    };
    getMetricsList();
  }, [getAccessTokenSilently, actor, setState, state]);

  const editMetricName = (metric, index, event) => {
    setSaveEnabled(true);

    const newMetricsList = structuredClone(metricsList);
    newMetricsList[index] = { ...metric, name: event.target.value };
    setMetricsList(newMetricsList);
  };

  const editMetricLevel = (metric, index, level, event) => {
    if (event.target.value !== '' && !DECIMALS_REGEXP.test(event.target.value)) return;

    setSaveEnabled(true);

    const newMetricsList = structuredClone(metricsList);
    newMetricsList[index] = { ...metric, levels: { ...metric.levels, [level]: event.target.value } };
    setMetricsList(newMetricsList);
  };

  const add = async () => {
    const newItem = {
      id: uuidv4(),
      name: '',
      levels: {
        0: '',
        1: '',
        2: '',
      },
    };
    setMetricsList((previous) => [...previous, newItem]);
  };

  //TODO: implement removeMetric, checking no team has it already

  const save = async (event) => {
    event.target.disabled = true;
    setState((previous) => ({ ...previous, success: null, error: null }));
    const url = `${BACKEND_URL}/company/${actor.company_id}/metrics`;
    patchData(url, metricsList, getAccessTokenSilently)
      .then(() => {
        setState((previous) => ({ ...previous, success: true, error: null }));
      })
      .catch((error) => {
        setState((previous) => ({ ...previous, error: error.message }));
      })
      .finally(() => {
        event.target.disabled = false;
        setSaveEnabled(false);
      });
  };

  if (error) return <p>{error.message}</p>;

  if (isLoading) return <Loading />;

  if (state.redirect) return <Navigate to="/landing" />;

  return (
    <div>
      {state.error && <Alert color="danger">{t('boostRequest.error')}</Alert>}
      {state.success && <Alert color="success">{t('metrics.success')}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.metrics')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
            {metricsList.length === 0 && (
              <Button className="btn btn-primary" color="primary" onClick={add}>
                {t('buttons.add')}
              </Button>
            )}
          </ButtonGroup>
          <FormGroup row>{metricsList.length === 0 && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>

          {metricsList.length !== 0 && (
            <Table hover borderless responsive size="sm" striped className="text-center">
              <thead>
                <tr>
                  <th>{t('tables.name')}</th>
                  <th>{t('metrics.title')}</th>
                </tr>
              </thead>
              <tbody>
                {metricsList.map((metric, index) => (
                  <tr key={metric.id}>
                    <td>
                      <input type="text" value={metric.name} onChange={(e) => editMetricName(metric, index, e)} />
                    </td>
                    <td>
                      {Object.keys(metric.levels).map((level) => (
                        <div>
                          <span className={`badge ${LEVEL_COLORS[level]}`}>{t(LEVEL_NAMES[level])}</span>
                          <input
                            type="text"
                            value={metric.levels[level]}
                            onChange={(e) => editMetricLevel(metric, index, level, e)}
                          />
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      <div>
        {metricsList.length === 0 && (
          <Button color="warning" onClick={add}>
            {t('buttons.add')}
          </Button>
        )}
      </div>
      <div>
        <Button color="primary" onClick={(e) => save(e)} disabled={!saveEnabled}>
          {t('buttons.ok')}
        </Button>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(MetricsList, {
  onRedirecting: () => <Loading />,
});
