import { useState, useCallback, useEffect } from 'react';
import { fetchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { setJackpot, useActor } from '../storage/storage';
import { useDispatch } from 'react-redux';

const useFetchJackpotList = () => {
  const actor = useActor();
  const [data, setData] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);

  const fetchJackpotsList = useCallback(async () => {
    const url = `${BACKEND_URL}/company/${actor.company_id}/jackpot`;
    setIsLoading(true);
    try {
      const { data } = await fetchData(url, getAccessTokenSilently);
      setData(data);
    } catch (error) {
      console.error('There was an error fetching the jackpots list!', error);
      setIsError('There was an error fetching the jackpots list!');
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, actor]);

  useEffect(() => {
    fetchJackpotsList();
  }, [fetchJackpotsList]);

  return { data, isLoading, isError, refetch: fetchJackpotsList };
};

const useStorageFetchActiveJackpot = () => {
  const dispatch = useDispatch();
  const { data } = useFetchActiveJackpot();

  useEffect(() => {
    if (data) {
      setJackpot(data, dispatch);
    }
  }, [data, dispatch]);
};

const useFetchActiveJackpot = () => {
  const actor = useActor();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState();

  const fetchActiveJackpot = useCallback(async () => {
    const url = `${BACKEND_URL}/company/${actor.company_id}/jackpot?active=true`;
    setIsLoading(true);
    try {
      const { data } = await fetchData(url, getAccessTokenSilently);
      setData(data);
    } catch (error) {
      console.error('There was an error getting the active jackpot', error);
      setIsError('There was an error getting the active jackpot');
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchActiveJackpot();
  }, [fetchActiveJackpot]);

  return { data, isLoading, isError, refetch: fetchActiveJackpot };
};

export { useFetchJackpotList, useFetchActiveJackpot, useStorageFetchActiveJackpot };
