import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAppAuthenticated: false,
  actor: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isAppAuthenticated = true;
      state.actor = action.payload;
    },
    logout: (state) => {
      state.isAppAuthenticated = false;
      state.actor = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
