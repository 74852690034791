import React, { useState } from 'react';
import { NavLink as RouterNavLink, Navigate, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/logo.svg';
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';

import { useFetchLogoCompany } from '../api/LogoAPI';

const NavBar = () => {
  const clientAppPaths = ['/client', '/player/profile', '/questionary'];
  const isInClientApp = () => {
    const currentPath = window.location.pathname;
    return !!clientAppPaths.find((path) => currentPath.indexOf(path) >= 0);
  };

  const { company, loading } = useFetchLogoCompany();
  const [isOpen, setIsOpen] = useState(false);

  const { user, isAuthenticated } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  if (isInClientApp()) return <Navigate to="/landing" />;

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md" container={false}>
        <Container>
          {isAuthenticated && <NavbarToggler onClick={toggle} />}
          <NavbarBrand href="/landing ">
            <img className="mb-3 app-logo" src={logo} alt="Galilei logo" width="80" />
          </NavbarBrand>
          <Collapse isOpen={isOpen} navbar>
            {!loading && company && (
              <Nav>
                <NavItem>
                  <img
                    id="logo"
                    className="mb-3 app-logo"
                    src={company.url}
                    alt={company.name}
                    style={{ width: '8em' }}
                  />
                </NavItem>
              </Nav>
            )}
            <Nav className="d-none d-md-block ms-auto" navbar>
              {isAuthenticated && (
                <UncontrolledDropdown nav setActiveFromChild>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img src={user.picture} alt="Perfil" className="nav-user-profile rounded-circle" width="50" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem id="qsLogoutBtn" onClick={() => navigate('/logout')}>
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {isAuthenticated && (
              <Nav className="d-md-none justify-content-between" navbar style={{ minHeight: 170 }}>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink to="/logout" id="qsLogoutBtn">
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
