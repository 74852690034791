import TotalCorrectAnswersPerTeam from './components/stats/team/TotalCorrectAnswersPerTeam';
import TotalGameTimePerTeam from './components/stats/team/TotalGameTimePerTeam';
import TotalPrizeBoostsPerTeam from './components/stats/team/TotalPrizeBoostsPerTeam';
import TotalPrizesRedeemedPerTeam from './components/stats/team/TotalPrizesRedeemedPerTeam';
import TotalGameDaysPerTeam from './components/stats/team/TotalGameDaysPerTeam';
import DailyGamingHours from './components/stats/global/DailyGamingHours';
import PrizeDetailsPerPlayer from './components/stats/player/PrizeDetailsPerPlayer';
import ResponseTimePerTeam from './components/stats/team/ResponseTimePerTeam';

const AVAILABLE_STATS = [
  {
    label: 'Horario de Juego',
    name: 'dailyGamingHours',
    group: 'Global',
    component: DailyGamingHours,
  },
  {
    label: 'Total de Respuestas Correctas',
    name: 'totalCorrectAnswersPerTeam',
    group: 'Por Equipo',
    component: TotalCorrectAnswersPerTeam,
  },
  {
    label: 'Total de Dias Jugados',
    name: 'totalGameDaysPerTeam',
    group: 'Por Equipo',
    component: TotalGameDaysPerTeam,
  },
  {
    label: 'Total de Horas Jugadas',
    name: 'totalGameTimePerTeam',
    group: 'Por Equipo',
    component: TotalGameTimePerTeam,
  },
  {
    label: 'Total de Premios Reclamados',
    name: 'totalPrizesRedeemedPerTeam',
    group: 'Por Equipo',
    component: TotalPrizesRedeemedPerTeam,
  },
  {
    label: 'Total de Boosts de Premios',
    name: 'totalPrizeBoostsPerTeam',
    group: 'Por Equipo',
    component: TotalPrizeBoostsPerTeam,
  },
  {
    label: 'Premios reclamados',
    name: 'prizeDetailsPerPlayer',
    group: 'Por Jugador',
    component: PrizeDetailsPerPlayer,
  },
  {
    label: 'Tiempo de Respuesta',
    name: 'responseTimePerTeam',
    group: 'Por Equipo',
    component: ResponseTimePerTeam,
  },
];

export default AVAILABLE_STATS;
