import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const PieChart = ({ data, colors, dimensions }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (!d3Container.current) return;

    // Clear previous chart
    d3.select(d3Container.current).selectAll('*').remove();

    const { width, height } = dimensions;
    const radius = Math.min(width, height) / 2;
    const finalInnerRadius = radius / 2;

    const svg = d3
      .select(d3Container.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const color = d3.scaleOrdinal(colors);

    const pie = d3
      .pie()
      .value((d) => d.value)
      .sort(null);

    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    const arcTween = (d) => {
      const i = d3.interpolate(d.startAngle, d.endAngle);
      return (t) => {
        d.endAngle = i(t);
        return arc(d);
      };
    };

    const innerRadiusTween = (d) => {
      const i = d3.interpolate({ innerRadius: 0 }, { innerRadius: finalInnerRadius });
      return (t) => {
        const interpolated = i(t);
        return arc.innerRadius(interpolated.innerRadius)(d);
      };
    };

    const arcs = svg.selectAll('arc').data(pie(data)).enter().append('g').attr('class', 'arc');

    arcs
      .append('path')
      .attr('fill', (d) => color(d.data.label))
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .transition()
      .duration(1000)
      .attrTween('d', function (d) {
        const interpolate = d3.interpolate(d.startAngle, d.endAngle);
        return (t) => {
          d.endAngle = interpolate(t);
          return arcTween(d)(t);
        };
      })
      .transition()
      .duration(1000)
      .attrTween('d', innerRadiusTween);

    const text = arcs
      .append('text')
      .attr('dy', '0.35em')
      .style('text-anchor', 'middle')
      .text((d) => d.data.label);

    text
      .transition()
      .duration(1000)
      .attrTween('transform', function (d) {
        const interpolate = d3.interpolate(d.startAngle, d.endAngle);
        return function (t) {
          d.endAngle = interpolate(t);
          const updatedArc = d3.arc().innerRadius(finalInnerRadius).outerRadius(radius);
          return `translate(${updatedArc.centroid(d)})`;
        };
      });

    arcs
      .append('text')
      .attr('dy', '0.35em')
      .style('text-anchor', 'middle')
      .text((d) => d.data.month);
  }, [data, colors, dimensions]);

  return <svg className="d3-component" ref={d3Container} />;
};

export default PieChart;
