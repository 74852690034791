import Loading from '../components/Loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Table, FormGroup, Alert, Card, CardBody, CardHeader, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import ReturnButton from '../components/ReturnButton';
import { BACKEND_URL } from '../utils/constants';
import { applyHeaders } from '../api/axiosClient';
import { useTeam } from '../storage/storage';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const PlayerList = () => {
  const { isLoading, error, getAccessTokenSilently } = useAuth0();
  const [playerList, setPlayerList] = useState();
  const [state, setState] = useState({
    error: null,
  });
  const { id } = useParams();
  const team = useTeam();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    const getPlayerList = async () => {
      if (state.error || !id) return;
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios
        .get(`${BACKEND_URL}/team/${id}/player`, headers)
        .then((response) => setPlayerList(response.data.data))
        .catch((error) => {
          if (error.response.status === 404) {
            setPlayerList(null);
            return;
          }
          setState({
            ...state,
            error: error.response.data.message,
          });
        });
    };
    getPlayerList();
  }, [getAccessTokenSilently, id, setState, state]);

  if (error) return <p>{error.message}</p>;

  if (isLoading) return <Loading />;

  return (
    <div>
      <ReturnButton to={'/team'} />
      {state.error && <Alert color="danger">{state.error}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{team.name}</h3>
        </CardHeader>
        <CardBody>
          <FormGroup row>{!playerList && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>
          {playerList && (
            <Table hover responsive size="sm" striped className="text-center">
              <thead>
                <tr key="header">
                  <th>{t('tables.name')}</th>
                  <th>{t('tables.active')}</th>
                </tr>
              </thead>
              <tbody>
                {playerList.map((player) => (
                  <tr key={player.player_id}>
                    <td>{player.full_name}</td>
                    <td>
                      <FormGroup switch>
                        <Input type="switch" checked={player.active} onChange={() => player.active} />
                      </FormGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default withAuthenticationRequired(PlayerList, {
  onRedirecting: () => <Loading />,
});
