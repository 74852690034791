import axios from 'axios';

import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';

import { updateTeam } from '../../state/team';
import { formatDate, isSameDay } from '../../utils/date';
import { applyHeaders } from '../../api/axiosClient';
import { BACKEND_URL } from '../../utils/constants';
import { useDispatch } from 'react-redux';

import ErrorModal from './ErrorModal';
import { useActor, useTeam } from '../../storage/storage';
import '../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';

const BoostHandler = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const actor = useActor();
  const team = useTeam();

  const [active, setActive] = useState(false);
  const [enable, setEnable] = useState(false);
  const [message, setMessage] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (!team) return;
    if (!team.boost_started && !team.boost_ended) {
      setMessage(t('game.profile.boost.no'));
      setEnable(true);
      return;
    }

    const now = new Date();
    const boostEnded = new Date(team.boost_ended);
    const endedFormatted = formatDate(boostEnded);
    const at = formatDate(boostEnded).split(' ');
    const sameDay = isSameDay(team.boost_ended);

    const resolved = `${at[1]} ${at[2]}`;
    if (boostEnded > now) {
      setMessage(`${t('game.profile.boost.until')}${sameDay ? t('game.profile.boost.tomorrow') : ''} ${resolved}`);
      setEnable(false);
      setActive(true);
      return;
    }

    if (sameDay) {
      setMessage(`${t('game.profile.boost.sameDayEnded')} ${resolved} ${t('game.profile.boost.return')}.`);
      setEnable(false);
      setActive(false);
      return;
    }

    setMessage(`${t('game.profile.boost.ended')} ${endedFormatted}`);
    setEnable(true);
    setActive(false);
  }, [team, setEnable, setMessage, setActive]);

  const boostTeam = async () => {
    if (!actor && !team) return;
    try {
      const headers = await applyHeaders(getAccessTokenSilently);
      const res = await axios.patch(
        `${BACKEND_URL}/team/${team.team_id}/boost`,
        {
          actorId: actor.actor_id,
        },
        headers
      );

      dispatch(updateTeam(res.data));
    } catch (error) {
      if (error?.response?.status === 400) {
        setUpdateError(error?.response?.data?.message);
        setShowErrorModal(true);
      }
    }
  };

  return (
    team && (
      <div className="sub-container">
        {!enable && (
          <>
            <span>
              <FontAwesomeIcon icon={faBolt} className={active ? 'faYellow' : 'faWhite'} />
            </span>
            <span>&nbsp;{message}&nbsp;</span>
          </>
        )}
        {enable && (
          <>
            <h2>{`${message} ${t('game.profile.boost.active')}:`}</h2>
            <button className="booster-button" onClick={() => boostTeam()}>
              <span>+ 60 {t('time.sec')}</span>
            </button>
          </>
        )}
        {showErrorModal && <ErrorModal updateError={updateError} handleClick={() => setShowErrorModal(false)} />}
      </div>
    )
  );
};

export default BoostHandler;
