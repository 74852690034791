import axios from 'axios';
import Loading from '../components/Loading';
import ReturnButton from '../components/ReturnButton';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import { BACKEND_URL, DECIMALS_REGEXP, LEVEL_COLORS, LEVEL_NAMES } from '../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { applyHeaders } from '../api/axiosClient';
import { formatInterval, formatDate } from '../utils/date';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useActor } from '../storage/storage';

const REQUEST_STATUS_LABELS = {
  SUBMITTED: 'boostRequest.pending',
  APPROVED: 'boostRequest.approved',
  REJECTED: 'boostRequest.rejected',
  EXPIRED: 'boostRequest.expired',
};

const REQUEST_STATUS_COLORS = {
  SUBMITTED: 'warning',
  APPROVED: 'success',
  REJECTED: 'danger',
  EXPIRED: 'secondary',
};
const BoostRequestList = () => {
  const actor = useActor();
  const { isLoading, error, getAccessTokenSilently } = useAuth0();

  const [action, setAction] = useState({
    success: false,
    message: null,
  });
  const [state, setState] = useState({
    error: null,
  });
  const [modal, setModal] = useState(false);
  const [request, setRequest] = useState();
  const [requestList, setRequestList] = useState([]);
  const { t, i18n } = useTranslation();
  const [enabledButtons, setEnabledButtons] = useState({});

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const getRequestList = async () => {
    try {
      const headers = await applyHeaders(getAccessTokenSilently);
      const requestsResponse = await axios.get(`${BACKEND_URL}/team/boost-requests`, headers);
      const requestList = requestsResponse.data;

      const metricsResponse = await axios.get(`${BACKEND_URL}/company/${actor.company_id}`, headers);
      const metricsList = metricsResponse.data.metrics;

      const enabledButtons = {};

      const enrichedRequestList = requestList.map((request) => {
        request.metrics = metricsList.map((metric) => ({
          id: metric.id,
          name: metric.name,
          [request.request_subject.to]: 0,
        }));
        enabledButtons[request.request_id] = false;
        return request;
      });

      setEnabledButtons(enabledButtons);
      setRequestList(enrichedRequestList);
    } catch (error) {
      if (error.response.status === 404) {
        setRequestList([]);
        return;
      }
      setState((previous) => ({
        ...previous,
        error: error.response.data.message,
      }));
    }
  };

  useEffect(() => {
    getRequestList();
  }, []);

  const resetRejectModal = () => {
    setRequest(null);
    setModal(false);
  };

  const openRejectModal = async (targetRequest) => {
    setRequest(targetRequest);
    setModal(true);
  };

  const changeStatus = async (requestId, reviewStatus) => {
    try {
      if (!requestId) return;

      const body = {
        reviewStatus,
        reviewComment: request?.reviewComment,
        metrics: requestList.find((r) => r.request_id === requestId).metrics,
      };
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios.patch(`${BACKEND_URL}/team/boost-requests/${requestId}`, body, headers);

      setAction({
        success: true,
        message: `${t('boostRequest.yourRequest')} ${t(REQUEST_STATUS_LABELS[reviewStatus])} ${t('boostRequest.successfully')}`,
      });

      setState((previous) => ({
        ...previous,
        error: null,
      }));

      await getRequestList();
    } catch (e) {
      setState({
        ...state,
        error: t('boostRequest.error'),
      });
    } finally {
      resetRejectModal();
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setRequest({
      ...request,
      reviewComment: value,
    });
  };

  const close = () => setModal(false);

  const resolveInfo = (request) => {
    if (request.status === 'SUBMITTED' && request.expires)
      return `${t('boostRequest.expires')} ${t('boostRequest.on')} ${formatInterval(request.expires_in, t)}`;
    if (request.status === 'REJECTED') return `${t('boostRequest.reason')}: ${request.review_comment || ''}`;

    if (request.status === 'EXPIRED')
      return `${request.review_comment} ${t('boostRequest.on')} ${formatDate(request.expires_at)}`;

    if (request.status === 'APPROVED')
      return `${t('boostRequest.approvedOn')} ${t('boostRequest.on')} ${formatDate(request.updated_at)}`;
  };

  const formatRequestSubject = (requestSubject) => {
    return (
      <>
        <span className={`badge ${LEVEL_COLORS[requestSubject.from]}`}>{t(LEVEL_NAMES[requestSubject.from])}</span>
        &nbsp;
        <FontAwesomeIcon icon={faArrowRight} />
        &nbsp;
        <span className={`badge ${LEVEL_COLORS[requestSubject.to]}`}>{t(LEVEL_NAMES[requestSubject.to])}</span>
      </>
    );
  };

  const editMetricLevel = (requestId, metricId, to, event) => {
    if (event.target.value !== '' && !DECIMALS_REGEXP.test(event.target.value)) return;

    const newRequestList = structuredClone(requestList);
    const newRequest = newRequestList.find((r) => r.request_id === requestId);
    const newMetric = newRequest.metrics.find((m) => m.id === metricId);
    newMetric[to] = event.target.value;
    setRequestList(() => newRequestList);
    setEnabledButtons((previous) => ({ ...previous, [requestId]: event.target.value !== '' }));
  };

  if (error) return <p>{error.message}</p>;

  if (isLoading) return <Loading />;

  return (
    <div>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      {action.success && <Alert color="success">{action.message}</Alert>}
      <Card className="my-2" color="light" outline>
        <CardHeader>
          <h3>{t('menu.metricsUpgrade')}</h3>
        </CardHeader>
        <CardBody>
          <ButtonGroup>
            <ReturnButton to={'/landing'} />
          </ButtonGroup>
          <FormGroup row>{requestList.length === 0 && <Alert color="light">{t('tables.empty')}</Alert>}</FormGroup>
          {requestList.length !== 0 && (
            <Table hover responsive size="sm" striped className="text-center middle-aligned">
              <thead>
                <tr key="header">
                  <th>{t('tables.name')}</th>
                  <th>{t('boostRequest.team')}</th>
                  <th>{t('boostRequest.change')}</th>
                  <th>{t('boostRequest.status')}</th>
                  <th>&nbsp;</th>
                  <th>{t('boostRequest.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {requestList.map((item) => (
                  <tr key={item.request_id}>
                    <td>{item.requester_name}</td>
                    <td>
                      <strong>{item.request_subject.team}</strong>
                    </td>
                    <td className="enlarged">{formatRequestSubject(item.request_subject)}</td>
                    <td className="enlarged">
                      <Badge className="status-badge" color={REQUEST_STATUS_COLORS[item.status]}>
                        {t(REQUEST_STATUS_LABELS[item.status])}
                      </Badge>
                    </td>
                    <td>{resolveInfo(item)}</td>
                    <td>
                      {item.metrics && item.status === 'SUBMITTED' && (
                        <Table hover borderless responsive size="sm" striped className="text-center">
                          <thead>
                            <tr>
                              <th>{t('metrics.title')}</th>
                              <th>{t('metrics.value')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {item.metrics.map((metric) => (
                              <tr key={metric.id}>
                                <td>
                                  <div>{metric.name}</div>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    value={metric[item.request_subject.to]}
                                    onChange={(e) =>
                                      editMetricLevel(item.request_id, metric.id, item.request_subject.to, e)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                      <ButtonGroup size="lg">
                        {item.status === 'SUBMITTED' && (
                          <>
                            <Button
                              id={`approve-${item.request_id}`}
                              color="success"
                              onClick={() => changeStatus(item.request_id, 'APPROVED')}
                              disabled={enabledButtons && !enabledButtons[item.request_id]}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </Button>
                            <UncontrolledTooltip placement="top" target={`approve-${item.request_id}`}>
                              Aprobar
                            </UncontrolledTooltip>

                            <Button
                              id={`reject-${item.request_id}`}
                              color="danger"
                              onClick={() => openRejectModal(item)}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </Button>
                            <UncontrolledTooltip placement="top" target={`reject-${item.request_id}`}>
                              Rechazar
                            </UncontrolledTooltip>
                          </>
                        )}
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      {request && (
        <Modal isOpen={modal} toggle={close}>
          <ModalHeader className="admin-text" toggle={close}>
            {t('boostRequest.rejectTitle')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col className="text-center admin-text">
                <strong>{request.request_subject.team}</strong>
                <br />
                {formatRequestSubject(request.request_subject)}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col className="text-center">
                <Label className="admin-text" for="reviewComment">
                  {t('boostRequest.reason')}
                </Label>
                <Input type="text" name="reviewComment" id="reviewComment" onChange={handleInputChange} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" size="sm" onClick={resetRejectModal}>
              {t('buttons.back')}
            </Button>
            <Button color="danger" size="sm" onClick={() => changeStatus(request.request_id, 'REJECTED')}>
              {t('buttons.reject')}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default withAuthenticationRequired(BoostRequestList, {
  onRedirecting: () => <Loading />,
});
