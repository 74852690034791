import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import Loading from '../../../components/Loading';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const PrizeTable = ({ counters, isLoading, error }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th className="text-center">{t('tables.name')}</th>
            <th className="text-center">{t('tables.available')}</th>
            <th className="text-center">{t('tables.redeemed')}</th>
          </tr>
        </thead>
        <tbody>
          {counters &&
            counters.map((record, index) => (
              <tr key={index}>
                <td className="text-center">{record.name}</td>
                <td className="text-center">{record.availability.max - record.availability.redeemed}</td>
                <td className="text-center">{record.availability.redeemed}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      {isLoading && (
        <div colSpan="3" className="spinner-container-fixed text-center">
          <Loading />
        </div>
      )}
      {error && (
        <div colSpan="3" className="text-center text-danger">
          Error al cargar la información de premios
        </div>
      )}
    </>
  );
};

PrizeTable.propTypes = {
  prizes: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
};

export default PrizeTable;
