import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  team: null,
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    updateTeam: (state, action) => {
      state.team = {
        ...state.team,
        ...action.payload,
      };
    },
  },
});

export const { updateTeam } = teamSlice.actions;
export const selectTeam = (state) => state.team;
export default teamSlice.reducer;
