import axios from 'axios';

import { BACKEND_URL, LEVEL_OPTIONS } from '../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { applyHeaders } from '../../api/axiosClient';
import { isAWeekAgo } from '../../utils/date';
import { useAuth0 } from '@auth0/auth0-react';

import gold from '../../assets/img/profile/gold-medal.png';
import silver from '../../assets/img/profile/silver-medal.png';
import bronze from '../../assets/img/profile/bronze-medal.png';
import { useTeam } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const UpdatePrizeLevelModal = ({ selectedLevel, resetSelection }) => {
  const { getAccessTokenSilently } = useAuth0();
  const team = useTeam();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const currentLevel = team.prize_level;

  const levelImages = [bronze, silver, gold];

  const updateLevel = async (level) => {
    try {
      const headers = await applyHeaders(getAccessTokenSilently);
      await axios.patch(
        `${BACKEND_URL}/team/${team.team_id}`,
        {
          prizeLevel: level,
        },
        headers
      );
      resetSelection();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="modal-container">
      {team && (!team.prize_level_updated_at || isAWeekAgo(team.prize_level_updated_at)) && (
        <>
          <h3>{t('game.profile.level.change')}</h3>
          <div className="sub-container">
            <div className="level-prize-transition">
              <div>
                <span>{t(LEVEL_OPTIONS[currentLevel].label)}</span>
                <div>
                  <img src={levelImages[currentLevel]} alt="" />
                </div>
              </div>
              <div>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
              <div>
                <span>{t(LEVEL_OPTIONS[selectedLevel].label)}</span>
                <div>
                  <img src={levelImages[selectedLevel]} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="sub-container">
            <button className="gali-button" onClick={() => updateLevel(selectedLevel)}>
              {t('buttons.go')}
            </button>
            <button className="gali-button" onClick={() => resetSelection()}>
              {t('buttons.back')}
            </button>
          </div>
        </>
      )}
      {team && team.prize_level_updated_at && !isAWeekAgo(team.prize_level_updated_at) && (
        <>
          <div className="incorrect-modal-title-container">
            <h3 className="incorrect-modal-title">{t('game.profile.level.requested')}</h3>
          </div>
          <hr />
          <div className="incorrect-modal-right-answer">
            <div className="level-prize-transition">
              <div>
                <span>{LEVEL_OPTIONS[currentLevel].label}</span>
                <div>
                  <img src={levelImages[currentLevel]} alt="" />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="sub-container">
            <button className="gali-button" onClick={() => resetSelection()}>
              {t('buttons.back')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default UpdatePrizeLevelModal;
