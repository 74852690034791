import { useState } from 'react';
import GameContainer from '../GameContainer';
import TabContainer from './TabContainer.js';
import DailyChest from './DailyChest';
import ChestContent from './ChestContent';
import { CHEST_AVAILABLE_TABS } from '../../utils/constants.js';

const Chest = () => {
  const [selectedItem, setSelectedItem] = useState(CHEST_AVAILABLE_TABS.DAILY_CHEST);

  return (
    <GameContainer active={'Chest'}>
      <div className="chest">
        <TabContainer selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        <div className="chest-container">
          {selectedItem === CHEST_AVAILABLE_TABS.DAILY_CHEST && <DailyChest />}
          {selectedItem === CHEST_AVAILABLE_TABS.CHEST_CONTENT && <ChestContent />}
        </div>
      </div>
    </GameContainer>
  );
};

export default Chest;
