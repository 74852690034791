import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'reactstrap';

import { useFetchStatistics } from '../../../../../api/StatisticsAPI';
import DottedLineChart from '../../charts/DottedLineChart';

const DailyGamingHours = ({ experienceId, label }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    fetchToken();
  }, [getAccessTokenSilently, setToken]);

  const { data: dailyGamingHours } = useFetchStatistics(experienceId, 'dailyGamingHours', token);
  const { data: dailyGamingHoursPerTeam } = useFetchStatistics(experienceId, 'dailyGamingHoursPerTeam', token);

  const chartDimensions = { width: 800, height: 500 };
  const chartLabels = { x: 'Hora del día', y: '# Juegos' };
  const chartData =
    dailyGamingHours &&
    dailyGamingHours.map((d) => {
      return {
        label: d.hour,
        value: d.count,
      };
    });

  const chartDataTeams = (t) => {
    return t.hours.map((h) => {
      return {
        label: h.hour,
        value: h.count,
      };
    });
  };

  return (
    <Container>
      <Table striped>
        <thead>
          <tr key="header">
            <th className="orderable-column text-center" colSpan={2}>
              {label}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="content-center">
              {dailyGamingHours ? (
                <DottedLineChart data={chartData} labels={chartLabels} dimensions={chartDimensions} />
              ) : (
                'No hay datos disponibles'
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      <Table striped>
        <thead>
          <tr key="header">
            <th className="orderable-column text-center" colSpan={2}>
              {label} por Equipo
            </th>
          </tr>
        </thead>
        <tbody>
          {dailyGamingHoursPerTeam
            ? dailyGamingHoursPerTeam.map((teamHours) => (
                <tr>
                  <td>{teamHours.name}</td>
                  <td className="content-center">
                    <DottedLineChart
                      data={chartDataTeams(teamHours)}
                      labels={chartLabels}
                      dimensions={chartDimensions}
                    />
                  </td>
                </tr>
              ))
            : 'No hay datos disponibles'}
        </tbody>
      </Table>
    </Container>
  );
};

export default DailyGamingHours;
