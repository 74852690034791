import { useState } from 'react';
import { fetchData, patchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setInventory, setPrizes, useExperience, useInventory, usePrizes } from '../storage/storage';
import { getPrizesWithDetails } from './PrizesAPI';

const usePatchInventory = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const inventory = useInventory();
  const experience = useExperience();

  const url = `${BACKEND_URL}/inventory/${inventory.inventory_id}/daily-chest`;

  const generateDailyChest = async () => {
    setLoading(true);
    setError(null);

    try {
      const result = await patchData(url, { experienceId: experience.experience_id }, getAccessTokenSilently);
      setResponse(result);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, generateDailyChest };
};

const useUpdateInventoryContext = () => {
  const { getAccessTokenSilently } = useAuth0();
  const inventory = useInventory();
  const prizes = usePrizes();
  const dispatch = useDispatch();

  const getUrl = `${BACKEND_URL}/inventory/${inventory.inventory_id}`;

  const updateInventoryContext = async () => {
    try {
      const res = await fetchData(getUrl, getAccessTokenSilently);
      setInventory(res, dispatch);
      setPrizes(getPrizesWithDetails(res, prizes), dispatch);
    } catch (error) {
      console.error('Error updating inventory.');
    }
  };

  return { updateInventoryContext };
};

export { usePatchInventory, useUpdateInventoryContext };
