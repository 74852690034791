import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useFetchRedemptionCounter } from '../../../api/ExperiencesAPI';
import PrizeTable from './PrizeTable';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const RemainingPrizesModal = ({ id: experienceId }) => {
  const [modal, setModal] = useState(false);
  const { counters, loading, error, getRedemptionCounter } = useFetchRedemptionCounter();
  const toggle = () => setModal(!modal);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (modal) {
      getRedemptionCounter(experienceId);
    }
  }, [modal, getRedemptionCounter, experienceId]);

  return (
    <>
      <Button color="link" size="sm" onClick={toggle}>
        {t('buttons.redeemedPrizes')}
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="admin-text" toggle={toggle}>
          {t('catalogue.prizes')}
        </ModalHeader>
        <ModalBody>
          <PrizeTable counters={counters} isLoading={loading} error={error} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            {t('buttons.ok')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

RemainingPrizesModal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default RemainingPrizesModal;
