import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import JigsawBuilder from '../JigsawBuilder';
import { DEFAULT_CATALOG_IMG } from '../../utils/constants';

const PrizeJigSaw = ({ prize, isLocked }) => {
  const navigate = useNavigate();
  const getCount = (pieces) => {
    return `${pieces.filter((e) => e > 0).length}/${pieces.length}`;
  };

  const handleDefaultImage = (e) => {
    e.target.src = DEFAULT_CATALOG_IMG[prize.level];
  };

  return (
    <div className="prize-container" onClick={() => navigate(`/prize-redemption/${prize.prize_id}`)}>
      <div className="prize-jigsaw-image">
        <img src={prize.url} alt="jigsaw" onError={(e) => handleDefaultImage(e)} />
        <div className="prize-jigsaw-grid">
          <JigsawBuilder isLocked={isLocked} pieces={prize.pieceCount} asIcon={false} />
        </div>
      </div>
      <div className="prize-jigsaw-info">
        <div className="jigsaw-info-title">
          <span>{prize.name}</span>
          <div>
            <JigsawBuilder isLocked={false} pieces={prize.pieceCount} asIcon={true} />
            <span>{getCount(prize.pieceCount)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

PrizeJigSaw.propTypes = {
  prize: PropTypes.shape({
    prize_id: PropTypes.string.isRequired,
    company_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    total_pieces: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    pieceCount: PropTypes.arrayOf(PropTypes.number).isRequired,
    repeatedPieces: PropTypes.number.isRequired,
    uniquePieces: PropTypes.number.isRequired,
  }).isRequired,
  isLocked: PropTypes.bool.isRequired,
};

export default PrizeJigSaw;
