import { useEffect, useState, useCallback } from 'react';
import { fetchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';

const useFetchPrizesList = (companyId) => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const url = `${BACKEND_URL}/company/${companyId}/prize`;

  useEffect(() => {
    const fetchPrizesList = async () => {
      try {
        const { data } = await fetchData(url, getAccessTokenSilently);
        setData(data);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the prizes list!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrizesList();
  }, [url, getAccessTokenSilently]);

  return { data, loading, error };
};

const useFetchPrizeById = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const getPrizeById = useCallback(
    async (prizeId) => {
      const url = `${BACKEND_URL}/prize/${prizeId}`;
      setLoading(true);
      try {
        return await fetchData(url, getAccessTokenSilently);
      } catch (error) {
        console.error('There was an error getting the prize by Id!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { loading, error, getPrizeById };
};

const getPrizesWithDetails = (inventory, prizes) => {
  return prizes.map((prize) => {
    const ownedPieces = inventory.prize_pieces.find((p) => p.prize_id === prize.prize_id);
    const pieceCount = getPieceCount(ownedPieces, prize.total_pieces);
    const repeatedPieces = pieceCount.reduce((a, b) => a + (b === 0 ? 0 : b - 1), 0);
    const uniquePieces = countUniquePieces(pieceCount);
    return {
      ...prize,
      repeatedPieces,
      uniquePieces,
      pieceCount,
    };
  });
};

const getPieceCount = (ownedPieces, totalPieces) => {
  const pieceCount = [];
  for (let i = 0; i < totalPieces; i++) {
    const pieceById = ownedPieces ? ownedPieces.pieces.find((p) => parseInt(p.id) === i) : null;
    const pieceCounter = pieceById ? pieceById.counter : 0;
    pieceCount.push(pieceCounter);
  }
  return pieceCount;
};

const countUniquePieces = (pieceCount) => {
  return pieceCount.reduce((acc, counter) => acc + (counter > 0 ? 1 : 0), 0);
};

export { useFetchPrizesList, useFetchPrizeById, getPrizesWithDetails };
