import { useNavigate } from 'react-router-dom';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useInventory } from '../../storage/storage';

const GameEnded = ({ countAnswered, currentScore }) => {
  const inventory = useInventory();
  const navigateTo = parseInt(inventory.bonus_available) > 0 ? '/bonus' : '/client';
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const buildMessage = () => {
    const score = currentScore && countAnswered ? Math.floor((currentScore / countAnswered) * 100) : 0;
    const played = (
      <>
        <span>{t('game.end.perform')}</span>
        <span>{`${currentScore}/${countAnswered} ${t('game.end.hits')} (${score}%)`}</span>
      </>
    );
    const ended = (
      <>
        <span>{t('game.end.good')}</span>
        <span>{t('game.end.return')}</span>
      </>
    );

    if (currentScore && countAnswered) {
      return (
        <>
          {ended}
          <br />
          {played}
        </>
      );
    }

    return ended;
  };

  const message = buildMessage();
  const navigate = useNavigate();

  return (
    <main className="client client-app">
      <div className="game-finished">
        {message}
        <button className="btn-gray btn-quiz-exit" onClick={() => navigate(navigateTo)}>
          {t('game.end.finish')}
        </button>
      </div>
    </main>
  );
};

export default GameEnded;
