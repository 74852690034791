import { useEffect } from 'react';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const ErrorModal = ({ updateError, handleClick }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);
  return (
    <div className="modal-container">
      <div className="sub-container">
        <h2>{t('game.profile.error')}</h2>
      </div>
      <hr />
      <h3>{updateError}</h3>
      <div className="sub-container">
        <button className="gali-button error" onClick={handleClick}>
          {t('buttons.ok')}
        </button>
      </div>
    </div>
  );
};

export default ErrorModal;
