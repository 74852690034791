import { hasRole } from '../utils/roleUtil';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import Stats from './profile/Stats';
import PrizeLevel from './profile/PrizeLevel';
import BoostHandler from './profile/BoostHandler.js';
import RedeemedPrize from './profile/RedeemedPrize';

import '../assets/css/perfil.css';
import { useActor, useAppAuthenticated, useTeam } from '../storage/storage.js';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/Loading.js';
import '../i18n/i18n.js';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const PlayerProfile = () => {
  const actor = useActor();
  const isAppAuthenticated = useAppAuthenticated();
  const team = useTeam();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const isPlayerLeader = isAppAuthenticated && hasRole(actor, 'LEADER');

  return (
    isAppAuthenticated &&
    actor && (
      <div>
        <h2 className="player-name">{actor?.full_name}</h2>
        {team && (
          <h2 className="team-name">
            {team.name} {isPlayerLeader && <>&nbsp;({t('game.profile.leader')})</>}
          </h2>
        )}
        {isPlayerLeader && <BoostHandler isPlayerLeader={isPlayerLeader} />}
        <Stats />
        <PrizeLevel isPlayerLeader={isPlayerLeader} />
        <RedeemedPrize />
        <div className="sub-container last">
          <button className="gali-button" onClick={() => navigate('/logout')}>
            {t('buttons.logout')}
          </button>
          {isPlayerLeader && (
            <button className="gali-button" onClick={() => navigate('/landing')}>
              {t('buttons.toAdmin')}
            </button>
          )}
        </div>
      </div>
    )
  );
};

export default withAuthenticationRequired(PlayerProfile, {
  onRedirecting: () => <Loading />,
});
