import React, { useRef, useState } from 'react';
import { useTeam } from '../../storage/storage';

const Question = ({ question, answers, handleAnswerSubmit, clean, setClean }) => {
  const team = useTeam();
  const [buttonClass, setButtonClass] = useState('');
  const ref = useRef();

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const changeButtonColor = async (selectedId) => {
    const isFree = question.validAnswerOptionId === '*';
    const isCorrect = isFree || question.validAnswerOptionId === selectedId;
    const correctClass = 'correct';
    const incorrectClass = 'incorrect';
    if (team.show_answer && !isFree) ref.current.className = correctClass;
    setClean(false);

    setButtonClass({
      class: isCorrect ? correctClass : incorrectClass,
      selectedId,
    });

    await timeout(200);

    await handleAnswerSubmit(selectedId, question.id);
  };

  const determineClass = (resultId) => {
    if (clean) {
      ref.current.className = '';
      return '';
    }
    return buttonClass?.selectedId === resultId ? buttonClass.class : '';
  };
  const getPropsButton = (result) => {
    const props = {
      className: determineClass(result.id),
      onClick: () => changeButtonColor(result.id),
    };
    if (result.id !== question.validAnswerOptionId) return props;
    return {
      ...props,
      ref: ref,
    };
  };

  return (
    <>
      <div className="question-area">
        {question.text && !question.image && (
          <div className="title-box">
            <i></i>
            <p>{question.text}</p>
          </div>
        )}
        {!question.text && question.image && (
          <div className="title-question">
            <img className="question-image" src={question.image} alt="" />
          </div>
        )}
        {question.text && question.image && (
          <>
            <div className="title-question">
              {question.image && <img className="question-image" src={question.image} alt="" />}
            </div>
            <p className="question-text">{question.text}</p>
          </>
        )}
      </div>
      <div className="button-array">
        {answers.map((result) => (
          <button key={result.id} {...getPropsButton(result)}>
            <span>{result.text}</span>
          </button>
        ))}
      </div>
    </>
  );
};

export default Question;
