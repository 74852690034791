import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import '../assets/css/index.css';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const Init = () => {
  const { loginWithRedirect } = useAuth0();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <div className="text-center Init my-5">
      <h1 className="mb-4">Galilei Learning App</h1>

      <p className="lead">{t('init')}.</p>
      <Button onClick={loginWithRedirect} color="primary">
        {t('buttons.login')}
      </Button>
    </div>
  );
};

export default Init;
