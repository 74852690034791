import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Label, Col, Input } from 'reactstrap';
import Loading from '../../components/Loading';
import { Navigate } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ReturnButton from '../../components/ReturnButton';
import { BACKEND_URL } from '../../utils/constants';
import { applyHeaders, postData } from '../../api/axiosClient';
import { setInventory, setPlayer, useActor } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const NewTeam = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const actor = useActor();
  const [team, setTeam] = useState({});
  const [state, setState] = useState({
    redirect: false,
    error: null,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const handleInput = (event) => {
    setTeam({
      ...team,
      actor: actor.actor_id,
      companyId: actor.company_id,
      [event.target.name]: event.target.value,
    });
  };

  const createInventory = async (playerId) => {
    try {
      const data = {
        playerId: playerId,
      };
      const postUrl = `${BACKEND_URL}/inventory/`;
      const inventory = await postData(postUrl, data, getAccessTokenSilently);
      setInventory(inventory, dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    try {
      const headers = await applyHeaders(getAccessTokenSilently);
      const response = await axios.post(`${BACKEND_URL}/team`, team, headers).then((res) => res.data);
      await createInventory(response.player.player_id);
      setPlayer(response.player, dispatch);
      setTeam(response.team, dispatch);
      setState({
        ...state,
        redirect: true,
      });
    } catch (e) {
      setState({
        ...state,
        error: e.response.data.message,
      });
    }
  };

  if (state.redirect) return <Navigate to="/team" />;

  return (
    <>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      <h2 className="admin-text">{t('menu.myTeam')}</h2>
      <Form onSubmit={submit}>
        <FormGroup row>
          <Label className="admin-text" for="name" sm={2}>
            {t('form.name')}
          </Label>
          <Col sm={6}>
            <Input id="name" name="name" placeholder={t('form.name')} type="text" onChange={handleInput} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Button color="primary" type="submit">
              {t('buttons.add')}
            </Button>
            <ReturnButton to={'/team'} />
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default withAuthenticationRequired(NewTeam, {
  onRedirecting: () => <Loading />,
});
