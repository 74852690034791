import axios from 'axios';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { isSameDay, isExpired } from '../../utils/date';
import { applyHeaders } from '../../api/axiosClient.js';
import { BACKEND_URL, POINTS_FOR_BONUS } from '../../utils/constants.js';
import { initGame, markAsPlayed, refreshQuestions } from '../../state/game.js';

import CountdownWorker from '../workers/CountdownWorker.js';
import FineCountdownWorker from '../workers/FineCountdownWorker';
import GameEnded from './GameEnded.js';
import FineModal from './FineModal.js';
import Loading from '../../components/Loading';
import Timer from '../Timer.js';
import ProgressBar from '../ProgressBar.js';
import Question from './Question.js';
import WorkerEnabler from '../workers/WorkerEnabler.js';
import Client from '../Client.js';

import '../../assets/css/questionary.css';
import { useExperience, useInventory, usePlayer } from '../../storage/storage.js';
import { useUpdateInventoryContext } from '../../api/InventoryAPI.js';
import { getQuestionByPriority } from './questions.js';

let timerWorker;
let fineWorker;

const Questionary = () => {
  if (!timerWorker) timerWorker = new WorkerEnabler(CountdownWorker);
  if (!fineWorker) fineWorker = new WorkerEnabler(FineCountdownWorker);
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const player = usePlayer();
  const experience = useExperience();
  const { game, countAnswered, isPlayed, question } = useSelector((state) => state.game);
  const { updateInventoryContext } = useUpdateInventoryContext();
  const inventory = useInventory();

  const [isFined, setIsFined] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clean, setClean] = useState(false);
  const [currentScore, setCurrentScore] = useState(game ? parseInt(game.score) : 0);
  const [bonusPoints, setBonusPoints] = useState(parseInt(inventory.bonus_points) % POINTS_FOR_BONUS);
  const penaltyReduction =
    inventory.fine_expires_at && !isExpired(inventory.fine_expires_at) ? inventory.penalty_reduction : 0;

  const emptyFn = (e) => e.preventDefault();

  const nextQuestion = () => dispatch(refreshQuestions(getQuestions(experience.questions, true)));

  const getQuestions = (input, dirty) => {
    setClean(dirty);
    return getQuestionByPriority(input);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', emptyFn);

    return () => {
      window.removeEventListener('beforeunload', emptyFn);
    };
  }, []);

  useEffect(() => {
    if (!player || !experience) return;
    const createGame = async () => {
      const headers = await applyHeaders(getAccessTokenSilently);
      try {
        setLoading(true);
        const gameParam = {
          playerId: player.player_id,
          experienceId: experience.experience_id,
        };
        const response = await axios.post(`${BACKEND_URL}/game`, gameParam, headers);
        const initialGame = {
          game: response.data,
          ...getQuestions(experience.questions, false),
        };
        dispatch(initGame(initialGame));
      } catch (error) {
        console.error(error);
        dispatch(markAsPlayed());
      } finally {
        setLoading(false);
      }
    };

    const getGame = async () => {
      if (game && !isPlayed && isSameDay(game.started_at)) return;
      await createGame();
    };
    getGame();
  }, [experience, game, dispatch, initGame, player]);

  const handleAnswerSubmit = async (resultId, questionId) => {
    if (isPlayed) return;
    try {
      const headers = await applyHeaders(getAccessTokenSilently);
      const answer = {
        question: questionId,
        selectedOption: resultId,
      };
      const response = await axios.patch(`${BACKEND_URL}/game/${game.game_id}/score`, answer, headers);
      const oldScore = currentScore;
      const newScore = response.data.score;
      const isCorrect = newScore > oldScore;

      setCurrentScore(newScore);

      if (!isCorrect && resultId !== question.validAnswerOptionId) {
        fineWorker = new WorkerEnabler(FineCountdownWorker);
        setIsFined(true);
        return;
      }
      const defBonus = bonusPoints + 1;
      setBonusPoints(defBonus > POINTS_FOR_BONUS ? 1 : defBonus);

      nextQuestion();
    } catch (error) {
      console.error('error: ', error);
      if (error.response.status === 400) dispatch(markAsPlayed());
    }
  };

  const onFineComplete = () => {
    fineWorker.postMessage({
      clear: true,
    });
    setIsFined(false);
    nextQuestion();
  };

  const endGame = async () => {
    if (isPlayed) return;
    try {
      setIsFined(false);
      timerWorker.postMessage({
        clear: true,
      });
      fineWorker.terminate();
      timerWorker.terminate();
      timerWorker = new WorkerEnabler(CountdownWorker);

      const headers = await applyHeaders(getAccessTokenSilently);
      await axios.patch(`${BACKEND_URL}/game/${game.game_id}`, {}, headers);
      dispatch(markAsPlayed());
    } catch (error) {
      if (
        error?.response?.status === 400 &&
        error?.response?.data &&
        error?.response?.data?.message.indexOf('completed')
      ) {
        dispatch(markAsPlayed());
        return;
      }
      if (error?.response?.status === 400) return;
      console.error('Something went wrong ending the game', error);
    } finally {
      await updateInventoryContext();
    }
  };

  if (!question) nextQuestion();

  if (loading) return <Loading />;

  if (!game) return <Client />;

  if (isPlayed) {
    window.removeEventListener('beforeunload', emptyFn);
    return <GameEnded currentScore={currentScore} countAnswered={countAnswered} />;
  }

  return (
    <main className="client client-app">
      <div className="questionary">
        <ProgressBar currentBonus={bonusPoints} pointsForBonus={POINTS_FOR_BONUS} />
        {game && <Timer game={game} endGame={endGame} worker={timerWorker} />}
        {question && (
          <Question
            question={question}
            answers={question.options}
            handleAnswerSubmit={handleAnswerSubmit}
            clean={clean}
            setClean={setClean}
          />
        )}
        {isFined && <FineModal onFinish={onFineComplete} worker={fineWorker} penaltyReduction={penaltyReduction} />}
      </div>
    </main>
  );
};
export default withAuthenticationRequired(Questionary, {
  onRedirecting: () => <Loading />,
});
