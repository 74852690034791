import { getLevelLabel, LEVEL_COLORS } from '../utils/constants';
import React, { useEffect } from 'react';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const LevelBadge = ({ level }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);
  return <span className={`badge ${LEVEL_COLORS[level]}`}>{t(getLevelLabel(level))}</span>;
};

export default LevelBadge;
