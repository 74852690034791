import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { applyHeaders } from '../../api/axiosClient';
import { BACKEND_URL } from '../../utils/constants';

import gold from '../../assets/img/profile/gold-medal.png';
import silver from '../../assets/img/profile/silver-medal.png';
import bronze from '../../assets/img/profile/bronze-medal.png';
import '../../assets/css/profile/prize-info-team.css';
import { useActor, useExperience, usePlayer, useTeam } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const RedeemedPrize = () => {
  const levelImages = [bronze, silver, gold];
  const actor = useActor();
  const player = usePlayer();
  const team = useTeam();
  const experience = useExperience();

  const { getAccessTokenSilently } = useAuth0();

  const [state, setState] = useState({
    error: null,
  });
  const [teamPrizes, setTeamPrizes] = useState(null);
  const [playerPrizes, setPlayerPrizes] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  useEffect(() => {
    if (!actor || !team || !experience) return;

    const getData = async (urlFragment, callback) => {
      try {
        const url = `${BACKEND_URL}/${urlFragment}/redeemed-prize`;
        const headers = await applyHeaders(getAccessTokenSilently);
        const res = await axios.get(url, headers);
        callback(res.data.data.length > 0 ? res.data.data : null);
      } catch (error) {
        if (error.response.status === 404) {
          callback(null);
        }
      }
    };

    getData(`company/${actor.company_id}`, setTeamPrizes);
    getData(`team/${team.team_id}`, setPlayerPrizes);
  }, [getAccessTokenSilently, team, actor, state, setState, setTeamPrizes, setPlayerPrizes]);

  if (!team) return;

  const existPlayerData = playerPrizes && playerPrizes.length;
  const existTeamData = teamPrizes && teamPrizes.length;
  const existData = existPlayerData || existTeamData;

  return (
    <>
      {!existData && (
        <div className="sub-container">
          <span>{t('game.profile.prizes.keepPlaying')}.</span>
        </div>
      )}
      {existPlayerData && (
        <div className="sub-container">
          <h1>{t('game.profile.prizes.byPlayer')}</h1>
          {playerPrizes.map((item) => (
            <div className={`prize-info-team-container ${item.player_id === player.player_id ? 'matched' : ''}`}>
              <div className="prize-info-team-side">
                <span>{item.full_name}</span>
              </div>
              <div className="prize-info-team-gain">
                <span>{item.total_prizes}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {existTeamData && (
        <div className="sub-container">
          <h1>{t('game.profile.prizes.byTeam')}</h1>
          {teamPrizes.map((item) => (
            <div className={`prize-info-team-container ${item.team_id === team.team_id ? 'matched' : ''}`}>
              <div className="prize-info-team-side">
                <span>{item.team_name}</span>
                <img src={levelImages[item.level]} alt="" />
              </div>
              <div className="prize-info-team-gain">
                <span>{item.total_prizes}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default RedeemedPrize;
