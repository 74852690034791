import { useEffect, useState } from 'react';
import '../../../assets/css/countdown/spinner.css';

const posX = (angle, radius, centerX) => Math.cos((Math.PI * angle) / 180) * radius + centerX;
const posY = (angle, radius, centerY) => Math.sin((Math.PI * angle) / 180) * radius + centerY;

const CountdownSpinner = ({ style }) => {
  const [innerCircleClass, setInnerCircleClass] = useState('normal-circle');
  const [outerCircleClass, setOuterCircleClass] = useState('normal-circle');
  const [backgroundClass, setBackgroundClass] = useState('normal-background');

  useEffect(() => {
    switch (style) {
      case 'home-danger':
        setInnerCircleClass('danger-inner-circle home-static');
        setOuterCircleClass('danger-outer-circle home-static');
        setBackgroundClass('danger-background home-static');
        break;
      case 'game-normal':
        setInnerCircleClass('normal-inner-circle');
        setOuterCircleClass('normal-outer-circle');
        setBackgroundClass('normal-in-game-background');
        break;
      case 'danger':
        setInnerCircleClass('danger-inner-circle');
        setOuterCircleClass('danger-outer-circle');
        setBackgroundClass('danger-background');
        break;
      case 'warning':
        setInnerCircleClass('warning-inner-circle');
        setOuterCircleClass('warning-outer-circle');
        setBackgroundClass('warning-background');
        break;
      default:
        setInnerCircleClass('normal-inner-circle');
        setOuterCircleClass('normal-outer-circle');
        setBackgroundClass('normal-background');
        break;
    }
  }, [style]);

  const svgWidth = 400;
  const svgHeight = 50;
  const centerX = svgWidth / 2;
  const centerY = svgHeight / 2;

  const viewBox = `0 0 ${svgWidth} ${svgHeight}`;

  const innerOrbit = 45;
  const outerOrbit = innerOrbit + 35;
  const externalOrbit = outerOrbit + 35;

  const angles = [
    [110, 230, 350],
    [20, 170, 90],
    [190, 10, 200],
  ];

  return (
    <svg
      width={`${svgWidth}`}
      height={`${svgHeight}`}
      viewBox={`${viewBox}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="bounding-box">
          <rect y="0" width={`${svgWidth}`} height={`${svgHeight}`} rx="8" fill="white" />
        </clipPath>
      </defs>
      <g clipPath="url(#bounding-box)">
        <rect
          className={`spinner-background ${backgroundClass}`}
          y="0"
          width={`${svgWidth}`}
          height={`${svgHeight}`}
          rx="8"
        />
        <circle className="orbit-circle" cx={`${centerX}`} cy={`${centerY}`} r={`${externalOrbit}`} />
        <circle className="orbit-circle" cx={`${centerX}`} cy={`${centerY}`} r={`${outerOrbit}`} />
        <circle className="orbit-circle" cx={`${centerX}`} cy={`${centerY}`} r={`${innerOrbit}`} />

        <circle
          className={`spinner-circle ${innerCircleClass}`}
          cx={`${posX(angles[0][0], innerOrbit, centerX)}`}
          cy={`${posY(angles[0][0], innerOrbit, centerY)}`}
          r="3.4"
        />
        <circle
          className={`spinner-circle ${innerCircleClass}`}
          cx={`${posX(angles[0][1], innerOrbit, centerX)}`}
          cy={`${posY(angles[0][1], innerOrbit, centerY)}`}
          r="3.4"
        />
        <circle
          className={`spinner-circle ${innerCircleClass}`}
          cx={`${posX(angles[0][2], innerOrbit, centerX)}`}
          cy={`${posY(angles[0][2], innerOrbit, centerY)}`}
          r="3.4"
        />

        <circle
          className={`spinner-circle ${outerCircleClass}`}
          cx={`${posX(angles[1][0], outerOrbit, centerX)}`}
          cy={`${posY(angles[1][0], outerOrbit, centerY)}`}
          r="3.4"
        />
        <circle
          className={`spinner-circle ${outerCircleClass}`}
          cx={`${posX(angles[1][1], outerOrbit, centerX)}`}
          cy={`${posY(angles[1][1], outerOrbit, centerY)}`}
          r="3.4"
        />
        <circle
          className={`spinner-circle ${outerCircleClass}`}
          cx={`${posX(angles[1][1], outerOrbit, centerX)}`}
          cy={`${posY(angles[1][2], outerOrbit, centerY)}`}
          r="3.4"
        />

        <circle
          className={`spinner-circle ${outerCircleClass}`}
          cx={`${posX(angles[2][0], externalOrbit, centerX)}`}
          cy={`${posY(angles[2][0], externalOrbit, centerY)}`}
          r="3.4"
        />
        <circle
          className={`spinner-circle ${outerCircleClass}`}
          cx={`${posX(angles[2][1], externalOrbit, centerX)}`}
          cy={`${posY(angles[2][1], externalOrbit, centerY)}`}
          r="3.4"
        />
        <circle
          className={`spinner-circle ${outerCircleClass}`}
          cx={`${posX(angles[2][2], externalOrbit, centerX)}`}
          cy={`${posY(angles[2][2], externalOrbit, centerY)}`}
          r="3.4"
        />
      </g>
    </svg>
  );
};

export default CountdownSpinner;
