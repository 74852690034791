import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { applyHeaders } from '../../api/axiosClient';
import { useAuth0 } from '@auth0/auth0-react';
import { BACKEND_URL, LEVEL_OPTIONS } from '../../utils/constants';

import { formatInterval } from '../../utils/date';
import UpdatePrizeLevelModal from './UpdatePrizeLevelModal';

import gold from '../../assets/img/profile/gold-medal.png';
import silver from '../../assets/img/profile/silver-medal.png';
import bronze from '../../assets/img/profile/bronze-medal.png';
import '../../assets/css/profile/team-metrics.css';
import { useTeam } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const PrizeLevel = ({ isPlayerLeader }) => {
  const levelImages = [bronze, silver, gold];

  const { getAccessTokenSilently } = useAuth0();
  const [enableChange, setEnableChange] = useState(false);
  const [changeRequest, setChangeRequest] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(-1);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const team = useTeam();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const getPrizeLevelChangeRequest = async () => {
    try {
      if (!team) return;
      const headers = await applyHeaders(getAccessTokenSilently);
      const res = await axios.get(`${BACKEND_URL}/team/boost-requests`, headers);
      const req = res.data[0];
      setChangeRequest(req);
      setEnableChange(determineChangeEnable(req));
    } catch (error) {
      console.log(error);
      if (error.response.status === 404) {
        setChangeRequest(null);
        setEnableChange(true);
      }
    }
  };

  useEffect(() => {
    getPrizeLevelChangeRequest();
  }, []);

  if (!team) return;

  const currentPrizeLevel = team.prize_level;

  const determineClassNames = (prizeLevel) => {
    let resolvedClass = 'metric-selection';
    resolvedClass = `${resolvedClass}${currentPrizeLevel === prizeLevel ? ' metric-selected' : ''}`;
    resolvedClass = `${resolvedClass}${prizeLevel === 0 ? ' metric-selection-left' : ''}`;
    resolvedClass = `${resolvedClass}${prizeLevel === 2 ? ' metric-selection-right' : ''}`;

    return resolvedClass;
  };

  const handleClick = (prizeLevel) => {
    if (currentPrizeLevel === prizeLevel || !enableChange) return;
    setSelectedLevel(prizeLevel);
    setShowConfirmationModal(true);
  };

  const resetSelection = async () => {
    setSelectedLevel(-1);
    setShowConfirmationModal(false);
    await getPrizeLevelChangeRequest();
  };

  const determineChangeEnable = (request) => {
    return request && request.status !== 'SUBMITTED' && request.status !== 'REJECTED';
  };

  const formatChangeRequest = () => {
    if (!changeRequest) return t('game.profile.level.choose');

    if (changeRequest.status === 'SUBMITTED')
      return `${t('game.profile.level.sent')}.
                ${changeRequest.expires ? `${t('game.profile.level.expiration')} ${formatInterval(changeRequest.expires_in, t)}` : ''}.`;

    if (changeRequest.status === 'REJECTED')
      return (
        <span>
          {t('game.profile.level.rejected')}: '{changeRequest.review_comment}'.
          <br />
          {t('game.profile.level.tryAgain')}
          {changeRequest.expires ? ` ${t('game.profile.level.on')} ${formatInterval(changeRequest.expires_in, t)}` : ''}
          .
        </span>
      );
  };

  return (
    <>
      <div className="sub-container">
        <h1>{t('game.profile.level.name')}</h1>
        {isPlayerLeader && (
          <span>
            {formatChangeRequest()}
            <br />
            <br />
          </span>
        )}
        <div className={isPlayerLeader ? 'metrics-lead' : 'metrics-box'}>
          {isPlayerLeader &&
            LEVEL_OPTIONS.map((element, idx) => (
              <div key={idx} className={determineClassNames(idx)} onClick={() => handleClick(idx)}>
                <span>{t(element.label)}</span>
                <div>
                  <img src={levelImages[idx]} alt="" />
                </div>
              </div>
            ))}
          {!isPlayerLeader && (
            <>
              <span>{t(LEVEL_OPTIONS[currentPrizeLevel]?.label)}</span>
              <div>
                <img src={levelImages[currentPrizeLevel]} alt="" />
              </div>
            </>
          )}
        </div>
      </div>
      {showConfirmationModal && <UpdatePrizeLevelModal selectedLevel={selectedLevel} resetSelection={resetSelection} />}
    </>
  );
};

export default PrizeLevel;
