import React, { useEffect, useState } from 'react';
import { usePrizes, useTeam } from '../../storage/storage.js';
import { useTranslation } from 'react-i18next';
import PrizesByLevel from './PrizesByLevel.js';
import GameContainer from '../GameContainer.js';
import JackpotCatalog from './JackpotCatalog';

import galiPiece from '../../assets/img/icons/galipiece.svg';
import galiTicket from '../../assets/img/icons/galiticket.svg';
import '../../assets/css/jigsaw.css';
import '../../assets/css/rewards.css';
import '../../i18n/i18n';

const PrizeCatalog = () => {
  const [activeTab, setActiveTab] = useState(1);
  const prizes = usePrizes();
  const team = useTeam();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const prizesBronze = prizes.filter((prize) => prize.level === 0);
  const prizesSilver = prizes.filter((prize) => prize.level === 1);
  const prizesGold = prizes.filter((prize) => prize.level === 2);

  return (
    <GameContainer active={'JigSaw'}>
      <div className="rewards-navigator sub-container">
        <button className={activeTab !== 1 ? 'disabled' : ''} onClick={() => setActiveTab(1)}>
          <img src={galiPiece} alt="GaliPieces" />
          <span>{t('game.catalogue.pieces')}</span>
        </button>
        <button className={activeTab !== 2 ? 'disabled' : ''} onClick={() => setActiveTab(2)}>
          <img src={galiTicket} alt="GaliTickets" />
          <span>{t('game.catalogue.tickets')}</span>
        </button>
      </div>
      <div className={activeTab === 1 ? 'active-tab' : 'inactive-tab'}>
        <PrizesByLevel
          title={t('game.catalogue.name.bronze')}
          isLocked={team && team.prize_level < 0}
          list={prizesBronze}
        />
        <PrizesByLevel
          title={t('game.catalogue.name.silver')}
          isLocked={team && team.prize_level < 1}
          list={prizesSilver}
        />
        <PrizesByLevel
          title={t('game.catalogue.name.gold')}
          isLocked={team && team.prize_level < 2}
          list={prizesGold}
        />
      </div>
      <div className={activeTab === 2 ? 'active-tab' : 'inactive-tab'}>
        <JackpotCatalog />
      </div>
    </GameContainer>
  );
};

export default PrizeCatalog;
