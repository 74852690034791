import { Outlet } from 'react-router-dom';
import NavBar from '../components/NavBar';
import { Container } from 'reactstrap';

const Admin = () => {
  return (
    <>
      <NavBar />
      <Container className="flex-grow-1 mt-5">
        <Outlet />
      </Container>
    </>
  );
};
export default Admin;
