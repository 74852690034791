import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const DottedLineChart = ({ data, labels, dimensions }) => {
  const d3Container = useRef(null);

  useEffect(() => {
    if (!d3Container.current) return;

    // Clear previous chart
    d3.select(d3Container.current).selectAll('*').remove();

    const { width, height } = dimensions;
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };

    const x = d3
      .scalePoint()
      .domain(data.map((d) => d.label))
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .nice()
      .range([height - margin.bottom, margin.top]);

    const line = d3
      .line()
      .x((d) => x(d.label))
      .y((d) => y(d.value))
      .curve(d3.curveMonotoneX);

    const svg = d3.select(d3Container.current).attr('width', width).attr('height', height);

    svg
      .append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(1));

    svg.append('g').attr('transform', `translate(${margin.left},0)`).call(d3.axisLeft(y));

    const path = svg
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1.5)
      .attr('d', line);

    // Animation for the line
    const totalLength = path.node().getTotalLength();
    path
      .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
      .attr('stroke-dashoffset', totalLength)
      .transition()
      .duration(2000)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);

    // Dots
    svg
      .append('g')
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', (d) => x(d.label))
      .attr('cy', (d) => y(d.value))
      .attr('r', 3)
      .attr('fill', 'steelblue');

    // X-axis label
    svg
      .append('text')
      .attr('class', 'x-axis-label')
      .attr('text-anchor', 'middle')
      .attr('x', width / 2)
      .attr('y', height)
      .text(labels.x);

    // Y-axis label
    svg
      .append('text')
      .attr('class', 'y-axis-label')
      .attr('text-anchor', 'middle')
      .attr('x', -height / 2 + margin.top)
      .attr('y', 15)
      .attr('transform', 'rotate(-90)')
      .text(labels.y);
  });

  return <svg ref={d3Container} />;
};

export default DottedLineChart;
