import { useEffect, useState } from 'react';
import { fetchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { setCompany, useActor } from '../storage/storage';

const useFetchLogoCompany = () => {
  const actor = useActor();
  const dispatch = useDispatch();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const getCompany = async () => {
      if (!actor || !isAuthenticated) return;

      await fetchData(`${BACKEND_URL}/company/${actor.company_id}`, getAccessTokenSilently)
        .then((response) => {
          setCompanyData(response);
          setCompany(response, dispatch);
        })
        .catch((error) => console.error(error));

      setLoading(false);
    };

    getCompany();
  }, [actor, getAccessTokenSilently, setCompany]);

  return { company: companyData, loading };
};

export { useFetchLogoCompany };
