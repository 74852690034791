const FineCountdownWorker = () => {
  const FINE_SECONDS = 5;

  let countdownInterval;
  let fineEndsAt;
  let effectiveFine;
  onmessage = function (event) {
    if (event.data.clear) {
      clearInterval(countdownInterval);
      return;
    }
    effectiveFine = FINE_SECONDS - (event.data.penaltyReduction || 0);
    initialize();
  };

  const initialize = () => {
    fineEndsAt = new Date();
    fineEndsAt.setSeconds(fineEndsAt.getSeconds() + effectiveFine);

    countdownInterval = setInterval(() => {
      countdown();
    }, 1000);

    countdown();
  };

  const countdown = () => {
    if (!fineEndsAt) return;

    const now = new Date();
    const nowFixed = now.getTime() - now.getTimezoneOffset() * 60000;
    const diff = fineEndsAt.getTime() - fineEndsAt.getTimezoneOffset() * 60000 - nowFixed;

    let message = {
      remainingTime: '0:00',
    };

    if (diff > 0) {
      const seconds = Math.ceil((diff % 6e4) / 1000);
      message = {
        remainingTime: `${seconds}`,
        ended: false,
      };
    } else {
      message = {
        ...message,
        ended: true,
      };
    }

    postMessage(message);
  };
};

export default FineCountdownWorker;
