export const handleSort = (data, sortConfig) => {
  const direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
  const sortedData = [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  return { sortedData, direction };
};

export const getSortIcon = (sortConfig, key) => {
  if (sortConfig.key === key) {
    return sortConfig.direction === 'ascending' ? '▼' : '▲';
  }
  return '';
};
