import { useState, useEffect, useCallback } from 'react';
import { fetchData, postData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useActor } from '../storage/storage';

const useFetchExperienceList = (lang) => {
  const actor = useActor();
  const { getAccessTokenSilently } = useAuth0();
  const [experienceList, setExperienceList] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!actor) return;
    const fetchExperienceList = async () => {
      try {
        const { data: experienceList } = await fetchData(
          `${BACKEND_URL}/company/${actor.company_id}/experiences?lang=${lang}`,
          getAccessTokenSilently
        );
        setExperienceList(experienceList);
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the experiences list!', error);
        setError(error);
      }
    };

    fetchExperienceList();
  }, [actor, lang, setExperienceList, getAccessTokenSilently]);

  return { experienceList, error };
};

const useFetchExperience = (experienceId) => {
  const [experience, setExperience] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const url = `${BACKEND_URL}/experience/${experienceId}`;

  useEffect(() => {
    const fetchExperience = async () => {
      try {
        const experience = await fetchData(url, getAccessTokenSilently);
        setExperience(experience);
      } catch (error) {
        console.error('There was an error fetching the experience!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchExperience();
  }, [getAccessTokenSilently, url]);

  return { experience, loading, error };
};

const useFetchRedemptionCounter = () => {
  const [counters, setCounters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const getRedemptionCounter = useCallback(
    async (experienceId) => {
      const url = `${BACKEND_URL}/experience/${experienceId}/redemption-counter`;
      setLoading(true);
      try {
        setCounters(await fetchData(url, getAccessTokenSilently));
      } catch (error) {
        if (error.response.status === 404) return;
        console.error('There was an error fetching the experience!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { counters, loading, error, getRedemptionCounter };
};

const usePostExperience = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const url = `${BACKEND_URL}/experience`;

  const postExperience = async (data) => {
    setLoading(true);
    setError(null);

    try {
      const result = await postData(url, data, getAccessTokenSilently, 'multipart/form-data');
      setResponse(result);
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { response, loading, error, postExperience };
};

export { useFetchExperienceList, useFetchExperience, usePostExperience, useFetchRedemptionCounter };
