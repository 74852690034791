import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jackpot: null,
};

const jackpotSlice = createSlice({
  name: 'jackpot',
  initialState,
  reducers: {
    initJackpot: (state, action) => {
      state.jackpot = action.payload;
    },
  },
});

export const { initJackpot } = jackpotSlice.actions;
export const selectJackpot = (state) => state.jackpot;
export default jackpotSlice.reducer;
