const hasRole = (actor, roles) => {
  const singleMatch = (role) => {
    const partRoles = actor?.rol?.split(',');
    return partRoles?.filter((r) => r === role).length > 0;
  };

  if (typeof roles === 'string') {
    return singleMatch(roles);
  }

  if (Array.isArray(roles)) {
    return roles.filter((r) => singleMatch(r)).length === roles.length;
  }

  return false;
};

export { hasRole };
