import React, { useEffect } from 'react';
import PrizeJigSaw from './PrizeJigSaw';

import closedLock from '../../assets/img/icons/closed-lock.svg';
import openLock from '../../assets/img/icons/open-lock.svg';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const PrizesByLevel = ({ title, isLocked, list }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);
  return (
    <div className="sub-container">
      <div className="prize-list-title">
        <span>{title}</span>
        <div className={`lock-info-pill ${isLocked ? 'locked' : ''}`}>
          <img src={isLocked ? closedLock : openLock} alt="lock" />
          <span>{isLocked ? t('game.catalogue.locked') : t('game.catalogue.unlocked')}</span>
        </div>
      </div>
      <div className="prize-list ">
        {list.map((prize) => (
          <PrizeJigSaw prize={prize} isLocked={isLocked} />
        ))}
      </div>
    </div>
  );
};

export default PrizesByLevel;
