import { useState, useCallback } from 'react';
import { patchData } from './axiosClient';
import { BACKEND_URL } from '../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import { useActor } from '../storage/storage';

const useExecuteDraw = () => {
  const [winner, setWinner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const actor = useActor();

  const getWinner = useCallback(
    async (experienceId) => {
      const url = `${BACKEND_URL}/experience/${experienceId}/drawing`;
      const body = {
        actorId: actor.actor_id,
      };
      setLoading(true);
      try {
        const response = await patchData(url, body, getAccessTokenSilently);
        setWinner(response);
      } catch (error) {
        console.error('There was an error getting the winner!', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [getAccessTokenSilently]
  );

  return { winner, loading, error, getWinner };
};

export { useExecuteDraw };
