import bronzeCatalogDefault from '../assets/img/default/bronze-catalog.jpg';
import silverCatalogDefault from '../assets/img/default/silver-catalog.jpg';
import goldCatalogDefault from '../assets/img/default/gold-catalog.jpg';

import bronzeFullScreenDefault from '../assets/img/default/bronze-fullscreen.jpg';
import silverFullScreenDefault from '../assets/img/default/silver-fullscreen.jpg';
import goldFullScreenDefault from '../assets/img/default/gold-fullscreen.jpg';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const POINTS_FOR_BONUS = 25;

const LEVEL_OPTIONS = [
  { value: '0', label: 'game.catalogue.bronze' },
  { value: '1', label: 'game.catalogue.silver' },
  { value: '2', label: 'game.catalogue.gold' },
];

const CATALOGUE_LEVEL_OPTIONS = [
  'game.catalogue.name.bronze',
  'game.catalogue.name.silver',
  'game.catalogue.name.gold',
];

const LEVEL_COLORS = ['bg-bronze', 'bg-silver', 'bg-gold'];
const LEVEL_NAMES = LEVEL_OPTIONS.map((e) => e.label);

const getLevelLabel = function (value) {
  return LEVEL_OPTIONS.find((e) => parseInt(e.value) === value)?.label;
};

const getCatalogueLevelLabel = (value) => CATALOGUE_LEVEL_OPTIONS[value];

const PIECE_OPTIONS = [
  { value: '4', label: '4' },
  { value: '6', label: '6' },
];

const CHEST_AVAILABLE_TABS = {
  DAILY_CHEST: 'DAILY_CHEST',
  CHEST_CONTENT: 'CHEST_CONTENT',
};

const DEFAULT_CATALOG_IMG = {
  0: bronzeCatalogDefault,
  1: silverCatalogDefault,
  2: goldCatalogDefault,
};

const DEFAULT_FULL_SCREEN_IMG = {
  0: bronzeFullScreenDefault,
  1: silverFullScreenDefault,
  2: goldFullScreenDefault,
};

const DEFAULT_LOTTERY = {
  name: 'lottery.defaultName',
  url: '',
  description: JSON.stringify({
    description: 'lottery.defaultDescription',
    requirements: 'lottery.defaultRequirements',
  }),
};

const DECIMALS_REGEXP = /^[1-9|0]+\.?[0-9]*$/;

export {
  getLevelLabel,
  getCatalogueLevelLabel,
  BACKEND_URL,
  LEVEL_OPTIONS,
  LEVEL_NAMES,
  LEVEL_COLORS,
  PIECE_OPTIONS,
  CHEST_AVAILABLE_TABS,
  POINTS_FOR_BONUS,
  DEFAULT_CATALOG_IMG,
  DEFAULT_FULL_SCREEN_IMG,
  DEFAULT_LOTTERY,
  DECIMALS_REGEXP,
};
