import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useExecuteDraw } from '../../../api/DrawAPI';
import Loading from '../../../components/Loading';
import '../../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const isEmpty = (object) => !object || Object.keys(object).length === 0;

const DrawModal = ({ id: experienceId, children }) => {
  const [modal, setModal] = useState(false);
  const { winner, loading, error, getWinner } = useExecuteDraw();
  const toggle = () => setModal(!modal);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    if (modal) {
      getWinner(experienceId);
    }
  }, [i18n, modal, getWinner, experienceId]);

  return (
    <>
      <Button color="link" size="sm" onClick={toggle}>
        {children}
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="admin-text" toggle={toggle}>
          {t('lottery.resultTitle')}
        </ModalHeader>
        <ModalBody>
          {isEmpty(winner) && loading && <Loading />}
          {isEmpty(winner) && error && <div>{t('tables.error')}</div>}
          {winner && (
            <div className="admin-text">
              {t('lottery.resultBody')} <b>{winner.full_name}</b>.
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            {t('buttons.ok')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

DrawModal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DrawModal;
