import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Alert, Label, Col, Input } from 'reactstrap';
import Loading from '../../components/Loading';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { BACKEND_URL } from '../../utils/constants';
import { applyHeaders, fetchData, postData } from '../../api/axiosClient';
import { setActor, setInventory, setPlayer, setTeam, useAppAuthenticated } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

const NewMember = () => {
  const { id } = useParams();
  const isAppAuthenticated = useAppAuthenticated();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [membership, setMembership] = useState(null);
  const [member, setMember] = useState({});
  const [state, setState] = useState({
    redirect: false,
    error: null,
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
    const fetchMembership = async () => {
      try {
        const res = await fetchData(`${BACKEND_URL}/membership/${id}`, getAccessTokenSilently);
        setMembership(res);
      } catch (error) {
        console.error(error);
        navigate('/no-membership');
      }
    };
    fetchMembership();
  }, [i18n]);

  if (isAppAuthenticated) return <Navigate to="/landing" />;

  const handleInput = (event) => {
    setMember({
      ...member,
      [event.target.name]: event.target.value,
    });
  };

  const createInventory = async (playerId) => {
    try {
      const data = {
        playerId: playerId,
      };
      const postUrl = `${BACKEND_URL}/inventory/`;
      const inventory = await postData(postUrl, data, getAccessTokenSilently);
      setInventory(inventory, dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    try {
      if (!membership) navigate('/');
      const body = {
        ...member,
        lang: i18n.resolvedLanguage,
      };
      const headers = await applyHeaders(getAccessTokenSilently);
      const res = await axios.post(`${BACKEND_URL}/membership/${id}`, body, headers).then((response) => response.data);
      if (res.players) {
        await createInventory(res.players[0].player.player_id);
        setActor(res.actor, dispatch);
        setPlayer(res.players[0].player, dispatch);
        setTeam(res.players[0].team, dispatch);
      } else {
        setActor(res, dispatch);
      }
      setState({
        ...state,
        redirect: true,
      });
    } catch (e) {
      setState({
        ...state,
        error: e.response.data.message,
      });
    }
  };

  if (state.redirect) return <Navigate to="/landing" />;

  if (membership && (membership.isAccepted || membership.isExpired)) return <Navigate to="/no-membership" />;

  return (
    <>
      {state.error && <Alert color="danger">{state.error}</Alert>}
      <h2 className="admin-text">{t('membership.new')}</h2>
      <Form onSubmit={submit}>
        <FormGroup row>
          <Label className="admin-text" for="fullName" sm={2}>
            {t('form.name')}:
          </Label>
          <Col sm={6}>
            <Input id="fullName" name="fullName" placeholder={t('form.name')} type="text" onChange={handleInput} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={6}>
            <Button color="primary" type="submit">
              {t('buttons.ok')}
            </Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};

export default withAuthenticationRequired(NewMember, {
  onRedirecting: () => <Loading />,
});
