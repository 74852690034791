import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BACKEND_URL, getCatalogueLevelLabel } from '../../utils/constants.js';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateInventoryContext } from '../../api/InventoryAPI';
import { applyHeaders } from '../../api/axiosClient';
import GameContainer from '../GameContainer';
import JigsawBuilder from '../JigsawBuilder';

import '../../assets/css/buttons.css';
import '../../assets/css/info-prize.css';
import hot from '../../assets/img/icons/hot.svg';
import backArrow from '../../assets/img/icons/back.svg';
import { useExperience, useInventory, usePrizes, useTeam } from '../../storage/storage.js';
import '../../i18n/i18n.js';
import { useTranslation } from 'react-i18next';

import { DEFAULT_FULL_SCREEN_IMG } from '../../utils/constants.js';
import { isValidUrl } from '../../utils/componentUtil.js';

const PrizeRedemption = () => {
  const { id } = useParams();
  const inventory = useInventory();
  const experience = useExperience();
  const team = useTeam();
  const prizes = usePrizes();
  const [prize, setPrize] = useState(prizes.find((p) => p.prize_id === id));
  const { getAccessTokenSilently } = useAuth0();
  const redeemed = !!inventory.prizes[prize.prize_id];
  const [info, setInfo] = useState({
    soldOut: experience.prizes[id].redeemed === experience.prizes[id].max,
    pricesLeft: experience.prizes[id].max - experience.prizes[id].redeemed,
    redeemed: redeemed,
    missingPieces: prize.uniquePieces !== prize.total_pieces,
    canRedeem: !redeemed && prize.uniquePieces === prize.total_pieces,
    locked: team.prize_level < prize.level,
  });
  const { updateInventoryContext } = useUpdateInventoryContext();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const update = async (url, data) => {
    const headers = await applyHeaders(getAccessTokenSilently);
    try {
      const response = await axios.patch(url, data, headers);
      await updateInventoryContext();
      return response.data;
    } catch (error) {
      if (error.response?.data?.message?.indexOf('remaining') > 0) {
        setInfo({
          ...info,
          soldOut: true,
        });
      }
      console.error('Error updating inventory.', error.code);
      return null;
    }
  };

  const redeem = async () => {
    const url = `${BACKEND_URL}/inventory/${inventory.inventory_id}/prize`;
    const response = await update(url, {
      prizeId: prize.prize_id,
      experienceId: experience.experience_id,
    });
    if (!response) return;
    setInfo({
      ...info,
      redeemed: true,
      canRedeem: false,
    });
    const newPrizeCount = prize.pieceCount.map((c) => (c - 1 === 0 ? 0 : c - 1));
    await exchangeByRedeemedPrize(true, newPrizeCount);
  };

  const exchangeByRedeemedPrize = async (redeemed, maxTransformation) => {
    const url = `${BACKEND_URL}/inventory/${inventory.inventory_id}/ticket`;
    const data = {
      prizeId: prize.prize_id,
      max: maxTransformation,
      redeemed: redeemed,
    };
    const response = await update(url, data);
    if (!response) return;
    const max = maxTransformation.reduce((accumulator, max) => {
      return accumulator + max;
    }, 0);
    let repeated = prize.repeatedPieces - max;
    let unique = prize.uniquePieces;

    if (repeated < 0) {
      unique = prize.uniquePieces + repeated;
      repeated = 0;
    }

    const countArray = maxTransformation.map((c, i) => {
      const count = prize.pieceCount[i];
      return count - c;
    });
    setPrize({
      ...prize,
      repeatedPieces: repeated,
      uniquePieces: unique,
      pieceCount: countArray,
    });
  };

  const buildStyle = (url, level) => {
    const validUrl = isValidUrl(url) ? url : DEFAULT_FULL_SCREEN_IMG[level];

    return {
      'background-position': 'center top',
      'background-repeat': 'no-repeat',
      'background-image': `linear-gradient(180deg, rgba(5, 18, 22, 0) 50.86%, rgba(5, 18, 22, 0.8) 73.11%),
                           linear-gradient(0deg, rgba(5, 18, 22, 0) 78.38%, rgba(5, 18, 22, 0.8) 97.37%),
                           linear-gradient(0deg, rgba(5, 18, 22, 0.3), rgba(5, 18, 22, 0.3)),
                           url(${validUrl})`,
    };
  };

  const buildButton = () => {
    const baseClass = 'gali-button';
    const redeemClass = 'primary';
    const lockedClass = 'locked';
    const unavailableClass = 'unavailable';

    let buttonClass;
    let disabled = true;
    let innerText;

    if (info.locked) {
      buttonClass = `${baseClass} ${lockedClass}`;
      innerText = `${t(getCatalogueLevelLabel(prize.level))} ${t('game.catalogue.locked')}`;
    }

    if (!info.locked && (info.missingPieces || info.soldOut || info.redeemed)) {
      buttonClass = `${baseClass} ${unavailableClass}`;
      if (info.missingPieces) innerText = t('game.redemption.missing');
      if (info.soldOut) innerText = t('game.redemption.soldOut');
      if (info.redeemed) innerText = t('game.redemption.redeemed');
    }

    if (info.canRedeem) {
      buttonClass = `${baseClass} ${redeemClass}`;
      disabled = false;
      innerText = t('game.redemption.redeem');
    }

    return (
      <button className={buttonClass} disabled={disabled} onClick={redeem}>
        {innerText}
      </button>
    );
  };

  return (
    <GameContainer active={'JigSaw'}>
      <div className="info-prize-container">
        <img
          className="info-prize-img-back-btn"
          src={backArrow}
          alt="jigsaw"
          onClick={() => navigate('/prize-catalog')}
        />
        <div className="info-prize-img" style={buildStyle(prize.url, prize.level)}></div>
        <div className="info-prize-info">
          <div className="jigsaw-info-header">
            <div className="jigsaw-info-title">
              <span>{prize && prize.name}</span>
              <div>
                <JigsawBuilder isLocked={false} pieces={prize.pieceCount} asIcon={true} />
                <span>
                  {prize.uniquePieces} / {prize && prize.total_pieces}
                </span>
              </div>
            </div>
            {!info.redeemed && !info.soldOut && (
              <>
                <div className="hot-info">
                  <img src={hot} alt="support" />
                  <span>
                    {t('game.redemption.only')} {info.pricesLeft} {t('game.redemption.left')}
                  </span>
                </div>
                <div>
                  <span>
                    ({prize.repeatedPieces}) {t('game.redemption.repeated')}
                  </span>
                </div>
              </>
            )}
          </div>
          <p className="info-prize-info-detail">{prize && prize.description}</p>

          {buildButton()}
          {info.redeemed && (
            <div className="redeemed-message">
              <p>{t('game.redemption.redeemedMess')}</p>
              <span>{t('game.redemption.enjoy')}</span>
            </div>
          )}
        </div>
      </div>
    </GameContainer>
  );
};
//TODO: button gift remove missing gift feature
export default PrizeRedemption;
