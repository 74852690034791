import React from 'react';
import Loading from '../../components/Loading';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import InviteActor from './InviteActor';

const InvitePlayer = () => <InviteActor role={'PLAYER'} navigateTo={'team'} />;

export default withAuthenticationRequired(InvitePlayer, {
  onRedirecting: () => <Loading />,
});
