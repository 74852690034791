import { useState, useEffect } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../utils/constants';

const useFetchStatistics = (experienceId, statisticsName, token) => {
  const url = `${BACKEND_URL}/experience/${experienceId}/statistics?name=${statisticsName}`;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) return;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the data!', error);
        setError(error);
        setLoading(false);
      });
  }, [url, token]);

  return { data, setData, loading, error };
};

export { useFetchStatistics };
