import { isSameDay } from '../utils/date';

import WorkerEnabler from '../client/workers/WorkerEnabler';
import NextChestCountdown from '../client/chest/NextChestCountdown';
import TimerCountdownWorker from '../client/workers/TimeCountdownWorker';
import { useInventory } from '../storage/storage';

const useCanOpenChest = () => {
  const inventory = useInventory();
  return !inventory.daily_obtained_at ? true : !isSameDay(inventory.daily_obtained_at) || inventory.bonus_available > 0;
};

const useNextChestWorker = () => {
  const createWorker = () => new WorkerEnabler(TimerCountdownWorker);
  return { createWorker };
};

const useNextChestCountdown = () => {
  const inventory = useInventory();

  const getNextChestCountdownComponent = (nextChestWorker, isHome) => {
    return (
      <NextChestCountdown
        startedAt={inventory.daily_obtained_at}
        worker={nextChestWorker}
        isHome={isHome}
        autoRestart={true}
      />
    );
  };

  return { getNextChestCountdownComponent };
};

export { useCanOpenChest, useNextChestWorker, useNextChestCountdown };
