import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GameContainer from '../GameContainer';

import backArrow from '../../assets/img/icons/back.svg';
import { useInventory, useJackpot } from '../../storage/storage.js';
import { useTranslation } from 'react-i18next';

import '../../assets/css/buttons.css';
import '../../assets/css/info-prize.css';
import '../../i18n/i18n.js';
import galiTicket from '../../assets/img/icons/galiticket.svg';
import lotteryDefault from '../../assets/img/default/lottery-fullscreen.jpg';
import { isValidUrl } from '../../utils/componentUtil.js';
import { DEFAULT_LOTTERY } from '../../utils/constants.js';

const JackpotDetail = () => {
  const jackpot = useJackpot();
  const inventory = useInventory();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const buildStyle = (url) => {
    const validUrl = isValidUrl(url) ? url : lotteryDefault;
    return {
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${validUrl})`,
    };
  };

  const details = JSON.parse(jackpot?.description || DEFAULT_LOTTERY.description);
  const translatedRequirements = !jackpot?.description
    ? t(details.requirements, { returnObjects: true })
    : details.requirements;

  return (
    <GameContainer active={'JigSaw'}>
      <div className="info-prize-container">
        <img
          className="info-prize-img-back-btn"
          src={backArrow}
          alt="jigsaw"
          onClick={() => navigate('/prize-catalog')}
        />
        <div className="info-prize-img" style={buildStyle(jackpot?.url || DEFAULT_LOTTERY.url)}></div>
        <div className="info-prize-info">
          <p className="info-reward-info-title">
            {jackpot?.name || t(DEFAULT_LOTTERY.name)}
            <br />
            <span>
              <img src={galiTicket} alt="GaliTickets" style={{ width: '20px' }} />
              &nbsp;{inventory.tickets.total}
            </span>
          </p>
          <p className="info-reward-info-detail">{jackpot?.description || t(details.description)}</p>

          {translatedRequirements?.length > 0 && (
            <p className="info-reward-info-detail">
              <bold>Requirements:</bold>
              <ul>
                {translatedRequirements.map((item) => (
                  <li style={{ fontWeight: 'lighter' }}>{item}</li>
                ))}
              </ul>
            </p>
          )}
        </div>
      </div>
    </GameContainer>
  );
};

export default JackpotDetail;
