import React, { useEffect } from 'react';
import '../assets/css/progress-bar.css';
import bonusGaliPiece from '../assets/img/icons/bonus-galipiece.svg';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';

const ProgressBar = ({ currentBonus, pointsForBonus }) => {
  const progress = `${parseInt((currentBonus / pointsForBonus) * 100)}%`;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  return (
    <div className="progress-container">
      <div className="progress-inner">
        <div className="progress-info">
          <span className="progress-text">{t('game.bonus.progress')}</span>
          <div className="progress-hits-container">
            <span>
              {currentBonus}/{pointsForBonus}
            </span>
          </div>
        </div>
        <div className="progress-bar-background">
          <div className="progress-bar" style={{ width: progress }} />
        </div>
        <img src={bonusGaliPiece} alt="Galiticket" />
      </div>
    </div>
  );
};

export default ProgressBar;
