import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PrizesTable from './components/PrizesTable';
import { usePostExperience } from '../../api/ExperiencesAPI';
import { validateFile } from '../../utils/componentUtil';
import { useActor } from '../../storage/storage';
import '../../i18n/i18n';
import { useTranslation } from 'react-i18next';
import ReturnButton from '../../components/ReturnButton';

const NewExperience = () => {
  const actor = useActor();

  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [selectedPrizes, setSelectedPrizes] = useState({});
  const [name, setName] = useState(null);
  const [creationResult, setCreationResult] = useState({
    redirect: false,
    error: null,
  });
  const [openAccordionItem, setOpenAccordionItem] = useState('1');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const { response, loading, error, postExperience } = usePostExperience();

  const isCompleted = () => !!name && !!actor.company_id && !!selectedPrizes && !isEmpty(selectedPrizes);

  const isEmpty = (object) => Object.keys(object).length === 0;

  const toggleAccordionItem = (id) => {
    if (openAccordionItem === id) {
      setOpenAccordionItem('0');
    } else {
      setOpenAccordionItem(id);
    }
  };

  const handleFile = async (event) => {
    const input = event.target.files[0];
    const error = validateFile(input);
    if (error) {
      setFile(null);
      return setFileError(error);
    }
    setFile(input);
    setFileError(null);
    toggleAccordionItem('3');
  };

  const submit = async (event) => {
    event.preventDefault();
    const newExperience = new FormData();
    newExperience.append('name', name);
    newExperience.append('companyId', actor.company_id);
    newExperience.append('prizes', JSON.stringify(selectedPrizes));
    newExperience.append('file', file);
    newExperience.append('actorId', actor.actor_id);
    await postExperience(newExperience)
      .then(() => setCreationResult({ ...creationResult, redirect: true }))
      .catch((error) => setCreationResult({ ...creationResult, error: error.message }));
  };

  const enableSubmit = () => {
    //no one-liner for legibility
    if (!isCompleted() || !!loading) return false;
    return !!(!response || error);
  };

  if (creationResult.redirect) return <Navigate to="/experience" />;

  return (
    <Container>
      <ReturnButton to={'/experience'} />
      {!!response && creationResult.error && <Alert color="danger">{creationResult.error}</Alert>}
      <Form onSubmit={submit}>
        <Accordion open={openAccordionItem} toggle={toggleAccordionItem}>
          <AccordionItem>
            <AccordionHeader targetId="1">
              <strong>{t('content.step1')}</strong>&nbsp;
              {name && <FontAwesomeIcon icon={faCheck} className="faGreen" />}
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <FormGroup row>
                <Label className="admin-text" for="name" sm={3}>
                  {t('form.name')}:{name && <FontAwesomeIcon icon={faCheck} className="faGreen" />}
                </Label>
                <Col>
                  <Input
                    id="name"
                    name="name"
                    placeholder={t('form.name')}
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
              </FormGroup>
              <Button color="primary" disabled={!name} onClick={() => toggleAccordionItem('2')}>
                Continuar
              </Button>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="2">
              <strong>{t('content.step2')}</strong>&nbsp;
              {file && <FontAwesomeIcon icon={faCheck} className="faGreen" />}
            </AccordionHeader>
            <AccordionBody accordionId="2">
              {fileError && <Alert color="danger">{fileError}</Alert>}
              <Input id="file" name="file" type="file" accept="application/pdf" onChange={handleFile} />
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="3">
              <strong>{t('content.step3')}</strong>&nbsp;
              {selectedPrizes && !isEmpty(selectedPrizes) && <FontAwesomeIcon icon={faCheck} className="faGreen" />}
            </AccordionHeader>
            <AccordionBody accordionId="3">
              <PrizesTable selectedPrizes={selectedPrizes} setSelectedPrizes={setSelectedPrizes} />
            </AccordionBody>
          </AccordionItem>
        </Accordion>
        <FormGroup row>
          <Row>
            <Col sm={6}>
              <Button color="primary" type="submit" disabled={!enableSubmit()}>
                {t('buttons.add')}
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </Container>
  );
};

export default NewExperience;
