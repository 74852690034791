import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Table } from 'reactstrap';

import { useFetchStatistics } from '../../../../../api/StatisticsAPI';
import DottedLineChart from '../../charts/DottedLineChart';

const DailyGamingHours = ({ experienceId, label }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessTokenSilently();
      setToken(token);
    };
    fetchToken();
  }, [getAccessTokenSilently, setToken]);

  const { data: responseTimePerTeam } = useFetchStatistics(experienceId, 'responseTimePerTeam', token);

  const chartDimensions = { width: 800, height: 500 };
  const chartLabels = { x: 'Fecha', y: 'Velocidad (# respuestas / seg)' };

  const chartDataTeams = (t) => {
    return t.speed.map((h) => {
      return {
        label: h.day,
        value: h.speed,
      };
    });
  };

  return (
    <Container>
      <Table striped>
        <thead>
          <tr key="header">
            <th className="orderable-column text-center" colSpan={2}>
              {label} por Equipo
            </th>
          </tr>
        </thead>
        <tbody>
          {responseTimePerTeam
            ? responseTimePerTeam.map((teamHours) => (
                <tr>
                  <td>{teamHours.name}</td>
                  <td className="content-center">
                    <DottedLineChart
                      data={chartDataTeams(teamHours)}
                      labels={chartLabels}
                      dimensions={chartDimensions}
                    />
                  </td>
                </tr>
              ))
            : 'No hay datos disponibles'}
        </tbody>
      </Table>
    </Container>
  );
};

export default DailyGamingHours;
