import { useDispatch, useSelector } from 'react-redux';
import { login } from '../state/auth';
import { initExperience } from '../state/experience';
import { initInventory } from '../state/inventory';
import { initPlayer } from '../state/player';
import { updateTeam } from '../state/team';
import { initCompany } from '../state/company';
import { initPrizes } from '../state/prizes';
import { initJackpot } from '../state/jackpot';

const useActor = () => {
  const { actor } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  return actor ? actor : getStorage('actor', dispatch, login);
};

const useAppAuthenticated = () => {
  const actor = useActor();
  return !!actor;
};

const useExperience = () => {
  const { experience } = useSelector((state) => state.experience);
  const dispatch = useDispatch();
  return experience ? experience : getStorage('experience', dispatch, initExperience);
};

const useGame = () => {
  const { game } = useSelector((state) => state.game);
  const dispatch = useDispatch();
  return game ? game : getStorage('game', dispatch, initExperience);
};

const useInventory = () => {
  const { inventory } = useSelector((state) => state.inventory);
  const dispatch = useDispatch();
  return inventory ? inventory : getStorage('inventory', dispatch, initInventory);
};

const usePlayer = () => {
  const { player } = useSelector((state) => state.player);
  const dispatch = useDispatch();
  return player ? player : getStorage('player', dispatch, initPlayer);
};

const usePrizes = () => {
  const { prizes } = useSelector((state) => state.prizes);
  const dispatch = useDispatch();
  return prizes.length ? prizes : getStorage('prizes', dispatch, initPrizes);
};

const useTeam = () => {
  const { team } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  return team ? team : getStorage('team', dispatch, updateTeam);
};

const useCompany = () => {
  const { company } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  return company ? company : getStorage('company', dispatch, initCompany);
};

const useJackpot = () => {
  const { jackpot } = useSelector((state) => state.jackpot);
  const dispatch = useDispatch();
  return jackpot ? jackpot : getStorage('jackpot', dispatch, initJackpot);
};

const setJackpot = (entry, dispatch) => {
  setStorage('jackpot', entry, dispatch, initJackpot);
};

const setPrizes = (entry, dispatch) => {
  setStorage('prizes', entry, dispatch, initPrizes);
};

const setInventory = (entry, dispatch) => {
  setStorage('inventory', entry, dispatch, initInventory);
};

const setPlayer = (entry, dispatch) => {
  setStorage('player', entry, dispatch, initPlayer);
};

const setTeam = (entry, dispatch) => {
  setStorage('team', entry, dispatch, updateTeam);
};

const setActor = (entry, dispatch) => {
  setStorage('actor', entry, dispatch, login);
};

const setExperience = (entry, dispatch) => {
  setStorage('experience', entry, dispatch, initExperience);
};

const setCompany = (entry, dispatch) => {
  setStorage('company', entry, dispatch, initCompany);
};

const getStorage = (key, dispatch, dispatcher) => {
  const entry = sessionStorage.getItem(key);
  if (entry) {
    const parsedEntry = JSON.parse(entry);
    dispatch(dispatcher(parsedEntry));
    return parsedEntry;
  }
  return undefined;
};

const setStorage = (key, entry, dispatch, dispatcher) => {
  const enriched = getEnrichedEntry(entry);
  dispatch(dispatcher(enriched));
  sessionStorage.setItem(key, JSON.stringify(enriched));
};

const getEnrichedEntry = (entry) => {
  if (entry instanceof Array) {
    return entry.map((e) => {
      return {
        ...e,
        ts: Date.now(),
      };
    });
  }
  return {
    ...entry,
    ts: Date.now(),
  };
};

export {
  useActor,
  useAppAuthenticated,
  useExperience,
  useGame,
  useInventory,
  usePlayer,
  usePrizes,
  useTeam,
  useCompany,
  useJackpot,
  setJackpot,
  setPrizes,
  setInventory,
  setPlayer,
  setTeam,
  setActor,
  setExperience,
  setCompany,
};
